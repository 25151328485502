.top-mentors-container {
	margin: 100px 32px; 
	padding: 50px; 
	display: flex;
	justify-content: center; 
	align-items: center; 
	background-color: #5a159e;
	border-radius: 20px;
  }
  
  .top-mentors-body {
	display: block;
	width: 200%;
    color:white;
  }
  .top-mentors-body h2 {
	color: white;
	font-weight: 500;
	text-align: center; 
  font-size:4.5rem;
  margin-bottom:10px;
  }
  
  .top-mentors-body p {
	color: white;
	text-align: center; 
  font-size:30px;
  margin-bottom:20px;
  }
  
  
  .top-mentors-body .mentor-name {
	  font-size: 20px;
    color: #5a159e;
    text-align:center;
    margin-top: 18px;
    margin-bottom: 0px;
    font-weight:600;
  }
  
  .top-mentors-body .mentor-role {
	  font-size: 12px;
    color: black;
    margin-top: 5px;
    text-align:center;
    margin-bottom: 0px;
  }

  .top-mentors-body .mentor-card-container{
    display:flex;
    justify-content:center;
    flex-wrap: wrap;
    gap: 20px;
  }

  .top-mentors-body .mentor-card-container .mentor-category {
    background-color: white;
    border-radius: 30px;
    padding: 10px;
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .top-mentors-body .mentor-card-container .mentor-category:hover{
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .top-mentors-body .mentor-card-container .mentor-category p {
    color:black;
    margin-bottom: 0;
    font-size:25px;
  }

  .top-mentors-body .mentor-card-container .mentor-card{
     background-color:white;
     width:200px;
     height:220px;
     border-radius:12px;
     position: relative;
  
  }
  .top-mentors-body .mentor-card-container .mentor-card .orange{
    background-color:orange;
    height:80px;
    border-color: transparent;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

  .top-mentors-body .mentor-card-container .mentor-card img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: white;  
    justify-content:center;
    margin-left:60px;
    margin-top:-40px;
  }

  .top-mentors-body .mentor-btns{
    display:flex;
    justify-content:space-around;
    gap:60px;
    background-color:#5a159e;
  }

  @media screen  and (max-width: 768px) {
    .top-mentors-body .title {
      font-weight: 400;
      font-size: 35px;
    }
    .top-mentors-body .sub-title {
      font-size: 18px;
      margin: 25px 0px;
    }
  }

  .top-mentors-body .mentor-card:hover{
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  } 
