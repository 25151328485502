

  .hero-main-container{ 
    width: 100%;
	height:auto;
    display:grid;
	background-color:white !important;
    grid-template-columns: auto auto;
    background: url("../../assets/homepage/Path-5.svg");
    background-repeat: no-repeat;
    background-position:right;
    background-position-x: 92vw;
    background-position-y: 10vh;
	padding-top:0px;
  margin-top:-20px;
  margin-bottom:70px;
  }
  
  .hero-main-container .join-our{
	    margin-left:0px;
  }

  .hero-main-container .join-our button{
	  min-width:400px;
	
  }

  .hero-main-container h3{
    font-size: 80px;
    color: rgb(230, 120, 31);
	text-align:center;
	padding-top:100px;
  }
  
.hero-main-container h2{
  font-size: 100px;
    padding-top: 0px;
    padding-left: 8vw;
    color: rgb(0, 0, 0);
	font-weight:bold;
}

  .hero-main-container .btn1{
      padding-left: 0px;
      
  }
 .hero-main-container .btn2{
    padding-left: 140px;
}

	.hero-main-container .herd-container{
		align-self:center;
	}

  .her-container > h2 {
    color: rgb(230, 120, 31);
    font-size: 130px;
    justify-content: left;
    margin-top: 0vh;
    margin-left: 8vw;
    text-shadow: 4px 4px #998d8d;
  }
  
  .her-container > h3 {
    color: rgb(0, 0, 0);
    font-size: 80px;
    justify-content: left;
    margin-top: 30vh;
    margin-left: 8vw;
  }

  .her-container > p {
    margin-top: 8px;
    color: rgb(0, 0, 0);
    font-size: 25px;
    margin-left: 8vw;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .her-btns {
    margin-top: 20vh;
    margin-left: 0vw;

  }
  
  .her-btns .btn {
    margin: 10px 30px;
  }

  .fa-users{
    font-size: 45px;
    position:absolute;
    left:-1px;
    top:-1px;
    padding: 2px;
    border:solid 4px orange; 
    border-radius: 100%;
  }


  @media screen and (max-width: 1300px) {

    .hero-main-container { 
      width: 100%;
      display:grid;
      grid-template-columns: auto;
      align-items: center;
	 height:auto;
	 margin-top:30px;
	 margin-bottom:20px;
    }
	
    .hero-main-container h3{
      font-size: 70px;
	  margin-top:0px;
    }
	
    .hero-main-container h2{
      font-size: 80px;
	  padding:0;
	  text-align:center;
    }
	
	 .her-btns{
		margin:0 auto;
		display:block;
	}
		

  }

 
  
  
  @media screen and (max-width: 900px) {
	.hero-main-container{
		margin-top:100px;
		margin-bottom:20px;
	}

	.hero-main-container  h3{
		  font-size:40px;
		  padding-top:30px;
	  }
	  
	 .hero-main-container h2{
		  font-size:25px;
	  }
	  
	.btn-mobile{
		display:flex !important;
		flex-wrap:wrap;
	}
	
	.her-btns .btn1{
		display:block ruby;
	}
	

  }
  
  @media screen and (max-width: 500px) {
	  
	  .hero-main-container{
		  padding-top:0px !important;
		margin-top:120px;
		margin-bottom:10px;
	}
	
	 .hero-main-container h3{
		 padding-top:20px;
	 }
	  
	  .her-btns .btn2{
		padding-left:0px;
		}
		
		 .her-btns button{
				min-width:130px !important;
		}
		
		.her-btns .btn1{
			margin-left:-20px;
		}
	
  }
  
  