/* FAQ.css */
.faq {
    background-color: #5a159e;
    padding: 60px 20px;
    margin: 0 auto;
  }
  
  .faq h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: white;
  }
  .faq p {
    color:white;
    text-align:center;
    font-size:1.5rem;
    margin-bottom: 20px;
  }
  
  .faq-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    /* transition: all 0.3s ease; */
    cursor: pointer;
    /* overflow: hidden; */
    /* max-height: 0; */
    transition: height 0.4s ease-out;
    text-align:left;
  }

  
  .faq-item h3 {
    font-size: 1.5rem;
    margin: 0;
    color: #333;
    position: relative;
  }
  
  .faq-item h3:hover {
    color: #5a159e;
  }
  
  .faq-item p {
    margin-top: 15px;
    font-size: 1.1rem;
    color: #555;
  }
  
  .faq-item h3:after {
    content: "+";
    position: absolute;
    right: 10px;
    font-size: 1.5rem;
    color: #007bff;
    transition: transform 0.3s ease;
  }
  
  .faq-item.active h3:after {
    content: "-";
    transform: rotate(180deg);
  }
  
  .faq-item.active p {
    display: block;
  }

.faq-container {
  text-align:center;
}
.faq-container button{
  padding:10px;
  background-color:#FF9500;
  border-radius:32px;
  border:none;
  padding:20px;
  color:white;
}