/* Container for the text */
.text-container {
    font-size: 2rem;
    font-family: Arial, sans-serif;
    border-right: 2px solid #5A159E;
    white-space: nowrap;
    overflow: hidden;
    color: #5A159E;
    font-weight: 600;
    animation: typing 3s infinite, blink 1s step-end infinite alternate;
  }

  /* Typing effect */
  @keyframes typing {
    0%, 100% { width: 0; }
    50% { width: 350px }
  }

  /* Blinking cursor effect */
  @keyframes blink {
    from { border-right-color: transparent; }
    to { border-right-color: #5A159E; }
  }

  @media screen and (max-width: 768px) {
    .text-container{
      font-size: 30px;
    }
  }