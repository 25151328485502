/* testimonial.css */

.testimonials {
  text-align: center;
  margin-bottom:30px;
}

.testimonials h2 {
  font-size: 4rem;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
  font-weight:600;
}

.testimonials h3 {
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 2rem;
  margin-top:20px;
}

.testimonial-cards {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 0.8s ease-in-out forwards;
  animation-delay: 0.2s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.testimonial-card {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 1.5rem;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.testimonial-card p {
  font-size: 0.95rem;
  color: #333;
  line-height: 1.5;
  margin-bottom: 2rem;
  font-style: italic;
}

.testimonial-card h4 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #1a1a1a;
  display:inline;
}


@media screen and (max-width: 768px) {

  .testimonials h2 {
    font-size: 30px;
  }

  .testimonials h3 {
    font-size: 1.3rem;
  }
}