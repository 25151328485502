@import url(https://cdn.jsdelivr.net/gh/jgthms/minireset.css@master/minireset.min.css);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap);
* {
  box-sizing: border-box; /* Include padding and borders in the element's width */
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%; /* Ensures body is no wider than the screen */
 
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*** Navigation ***/

.navBarContainer {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  padding: 6px;
  border-bottom: 1px solid #5a159e;
  margin-top:45px;
}

.navBarContainer .mentomeetLogo {
  display: flex;
  align-items: center;
}

.navBarContainer .mentomeetLogo a {
  text-decoration: none;
}

@media (max-width: 1100px) {
  .navBarContainer .mentomeetLogo .mentommetLogoText {
    display: none !important;
  }
}

.signupBtn {
  margin-right: 10px;
  background-color: transparent;
  color: #1e1e1e;
  padding: 8px 20px;
  border: none;
}

.signupBtn:hover {
  text-decoration: none;
  color: #5a159e;
}

.logoutBtn {
  background-color: transparent;
  color: #1e1e1e;
  padding: 8px 20px;
  border: none;
}

.logoutBtn:hover {
  color: #5a159e;
}

.loginBtn {
  background-color: #5a159e;
  color: white;
  padding: 12px 25px;
  border-radius: 8px;
  border: none;
}

.loginBtn:hover {
  color: #5a159e;
  border: 1px solid #5a159e;
  background-color: white;
  text-decoration: none;
}
.MentorBtn{
  background-color: transparent;
  color: #1e1e1e;
  padding: 8px 20px;
  border: none;
}
.MentorBtn:hover {
  color: #5a159e;
}

@media screen and (max-width: 768px) {
  .navBarContainer {
    grid-template-columns: 1fr 1fr;
  }

  .MentorBtn {
    padding: 0px;
  }

  .navbar-toggler {
    padding: 0px;
  }
}

@media screen and (min-width: 468px) and (max-width: 768px) {
  .navBarContainer {
    grid-template-columns: 1fr 4fr 2fr;
  }

}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: 0.1rem;
}

.dropdown-submenu:hover .dropdown-menu {
  display: block;
}


.ourTeamLink:hover {
  text-decoration: none;
}
#post #post-body #post-title{font-size:2em;color:gray;font-weight:600}#post #post-body #H-line{width:7%;height:2px;background-color:gray;display:block;font-size:.8em}#post #post-body p{font-size:.8em;border-top:1px solid gray;border-bottom:none}@media(max-width: 768px){#post{font-size:13px}}@media(max-width: 600px){#post{font-size:12px}}@media(max-width: 500px){#post{font-size:10px}}
#blog-side-bar #card{border-radius:5px;box-shadow:0px 0px 1px 1px gray}#blog-side-bar #card #author-img{left:calc(50% - 35px);top:115px;width:70px;border-radius:50%;border:4px solid #fff;margin-bottom:5px}#blog-side-bar #card #card-img-top{height:150px}#blog-side-bar #card #card-body{margin-top:20px}#blog-side-bar #card #card-body h5{font-weight:700}#blog-side-bar #card #card-body p{color:gray;font-size:.8em}#blog-side-bar #post-comment{background-color:#fff;border-radius:5px;box-shadow:0px 0px 1px 1px gray}@media(max-width: 992px){#blog-side-bar{display:flex;flex-direction:row;justify-content:space-around;align-items:flex-start}#blog-side-bar #card{max-width:45%}#blog-side-bar #post-comment{max-width:45%;margin-top:0px !important}}@media(max-width: 576px){#blog-side-bar{flex-direction:column;justify-content:space-around;align-items:center}#blog-side-bar #card{max-width:60%}#blog-side-bar #post-comment{max-width:60%;margin-top:1.2em !important}}@media(max-width: 440px){#blog-side-bar #card{max-width:80%}#blog-side-bar #post-comment{max-width:80%}}@media(max-width: 440px){#blog-side-bar #card{max-width:90%}#blog-side-bar #post-comment{max-width:90%}}
#nav-post{border-bottom:1px solid #d3d3d3;padding:.8rem}#nav-post div img{border-radius:50%}#nav-post div h5{font-size:.8em}#nav-post .post-title h5{font-size:.9em;font-weight:700;color:gray}#nav-post .post-title p{font-size:.8em;color:#d3d3d3}
#nav div a{font-size:.8em}
#column{height:400px}#column .card-body{font-size:16px}#column .card-body a{color:#000;font-weight:600}#column .card-body .details p{font-size:.8em}#column .card-body .details p .category{color:orange}#column .card-body .author{border-top:1px solid #d3d3d3}#column .card-body .author img{width:30px;height:30px;border-radius:50%}#column .card-body .author p{margin:0;font-size:.8em;font-weight:700;color:#353535}#column .card-body .author p span{font-weight:400}@media(max-width: 1200px){#column{height:370px}}@media(max-width: 992px){#column{height:390px}}@media(max-width: 768px){#column{height:370px;max-width:300px;margin:0 auto}}@media(max-width: 668px){#column{height:370px}}@media(max-width: 350px){#column{height:360px}}

.mentor-dashboard {
    display: flex; 
    /* height: 100vh; */
    overflow: hidden; 
  }
  
  .sidebar {
    width: 20%; 
    background-color: #f4f4f4; 
    padding: 20px; 
    display: flex;
    flex-direction: column; 
    justify-content: flex-start; 
    height: 100vh; 
    position: fixed; 
    left: 0;
    top: 0;
  }
  
  .sidebar h5 {
    font-size: 1.8rem; 
    font-weight: bold; 
    margin-bottom: 30px; 
  }
  
  .sidebar ul {
    list-style: none; 
    padding: 0; 
  }
  
  .sidebar li {
    margin-bottom: 15px; 
    font-size: 1.2rem; 
    cursor: pointer;
    color: #333; 
  }
  
  .sidebar li:hover {
    color: #6c63ff; 
  }

  
  .header {
    text-align: right; 
    margin-bottom: 20px; 
  }
  
  .header h1 {
    font-size: 2.5rem; 
  }
  
  .header p {
    font-size: 1.2rem;
    color: gray;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: flex-end; 
    margin-bottom: 20px; 
  }
  
  .find-mentor-btn,
  .schedule-meeting-btn {
    margin-right: 10px; 
    padding: 5px 10px;
    font-size: 0.95rem;
    border-radius: 5px; 
  }
  
  .find-mentor-btn {
    background-color: #6c63ff; 
    color: white; 
    border: none;
  }
  
  .schedule-meeting-btn {
    background-color: #ff6f61; 
    color: white; 
    border: none;
  }
  
  .question-list {
    margin-top: 20px; 
    display: flex;
    flex-wrap: wrap; 
    grid-gap: 20px; 
    gap: 20px;
  }
  
  .question-card {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(33.333% - 20px); 
    margin-bottom: 20px; 
    border: 1px solid #ddd;
    border-radius: 8px; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  }
  
  .details-btn {
    background-color: #6c63ff; 
    color: white; 
    border: none; 
    margin-top: 10px; 
    padding: 10px 15px; 
    font-size: 1rem; 
  }
  
#profile-card {
  text-align: center;
}

.card-img-top {
  width: 80%;
  margin: auto;
}

body {
  background: rgba(0,0,0,0.04);
}

.hidden {
  display: none;
}

#posts-jumbotron {
  padding-top: 12px;
}

#posts-top-menu {
  display: flex;
}

#posts-top-menu .right-buttons {
  margin-left: auto;
}

.posts-card {
  margin-top: 12px
}


.profile-section{
	display:flex;
	margin-bottom:50px;
	margin-top:50px;
	justify-content:center;
}

.profile-section .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background:white;
	margin:0px !important;
	padding:30px;
}
  
 .profile-section .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .profile-section .container1 {
    display: grid;
    grid-template-columns:1fr 2fr;
    padding:10px 4px !important;
	grid-gap:20px;
  }
  
  
  .profile-section .stars{
      padding-left: 30px;
      padding-top: 10px;
  }
  .profile-section .fa-star{
      padding-left: 10px;
      color: orange;
  }

  b{
      padding-left: 30px;
      font-size: 20px;
     
  }

  .profile-section .qualify{
      padding-left: 25px;
      padding-top: 10px;
  }

  .profile-section  .achieve{
      list-style-type: none;
  }

  .profile-section  .span{
      padding-left: 10px;
  }

  .profile-section .name-des{
      padding-left: 100px;
      padding-top: 15px;
  }

  .profile-section .name{
      font-size: 40px;
      font-weight: bold;
	  text-align:left;
	  margin-top:20px;
  }
  
  .profile-section .desig{
    font-size: 20px;
    color: grey;
	text-align:center;
  }
  
  .profile-section .container1 .image{
	  width:auto !important;
	  max-width:400px;
	  border-radius:50%;
  }
  
   .profile-section .container1 .image img{
	   border-radius:50%;
   }

  .profile-section .achieved{
      padding-top:40px;
  }
  
  .profile-section .achieve{
      font-size: 20px;
  }
  
   .profile-section .achieve ul{
	   text-align:left;
   }
  
  .profile-section li{
	padding-top: 8px;
	font-weight:300;
	text-align:left;
  }

  .profile-section .handle{
      padding-left: 20px !important;
      font-size: 50px;
  }
  
  .profile-section .handle .fa-instagram,.profile-section .handle .fa-linkedin{
	  background-color:white;
	  padding:5px;
	  color:black;
  }
  
 .profile-section  a{
      padding-right: 2vw;
      color: rgb(0, 0, 0);
  }

  .profile-section.percent{
      padding-left: 10vw;
  }

  .profile-section span1{
      color: orange;
      font-size: 20px;
  }
  
  @media screen and (max-width:500px){
	  
		.profile-section .name{
			margin-top:30px;
		}
	 .profile-section .achieved{
	   max-width:80vw;
	   margin-left:20px;
	   padding-top:0px;
   }
	  
  }
#profile-card {
  text-align: center;
}

.card-img-top {
  width: 80%;
  margin: auto;
}

body {
  background: rgba(0,0,0,0.04);
}

.hidden {
  display: none;
}

#posts-jumbotron {
  padding-top: 12px;
}

#posts-top-menu {
  display: flex;
}

#posts-top-menu .right-buttons {
  margin-left: auto;
}

.posts-card {
  margin-top: 12px
}


/* Overall Layout */
.mentee-history-container {
  display: flex;
  font-family: "Arial", sans-serif;
  background-color: #f8f9fa;
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: #343a40;
  color: white;
  padding: 20px;
}

.sidebar-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
}

.sidebar-menu li {
  padding: 10px 0;
  font-size: 16px;
  cursor: pointer;
  color: #ced4da;
}

.sidebar-menu li.active {
  color: #5a159e;
  font-weight: bold;
}

.sidebar-menu li:hover {
  color: #ffffff;
}

/* Main Content */
.main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #ffffff;
}

.page-title {
  font-size: 28px;
  color: #343a40;
  margin-bottom: 20px;
}

.history-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  gap: 20px;
}

/* History Card */
.history-card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
  transition: transform 0.3s ease;
}

.history-card:hover {
  transform: scale(1.02);
}

.card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.status-badge {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 15px;
  text-transform: capitalize;
  height: -moz-fit-content;
  height: fit-content;
}

.status-badge.approved {
  background-color: #ff9500;
  color: white;
}

.status-badge.pending {
  background-color: #ffc107;
  color: white;
}

.category {
  font-size: 14px;
  background-color: #5a159e;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
}

.card-body p {
  font-size: 14px;
  color: #343a40;
  margin-bottom: 10px;
}

.meet-link {
  display: inline-block;
  font-size: 14px;
  color: white;
  background-color: #5a159e;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}

.meet-link:hover {
  background-color: #ff9500;
}

.pending-message {
  color: #6c757d;
}

/* No History */
.no-history {
  font-size: 16px;
  color: #6c757d;
  text-align: center;
  margin-top: 20px;
}

#about{flex-direction:column;font-size:16px;height:auto}#about h1{font-size:3.6em;font-weight:900;color:orange;width:100%}#about .square{width:250px;height:200px;box-shadow:1px 1px 50px #dbdbdb;border-radius:15px}#about .square img{width:100px}#about .square p{font-size:1.7em;color:gray;margin-top:5px}
/* Existing styles */
.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 150px;
  margin-top: 60px;
}

.hero-section h2 {
  position: relative;
  font-size: 4.5rem;
  font-weight: bold;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  animation: slideInTop 1s ease-in-out;
  margin-top: 20px;
  color: #1a1a1a;
}

.hero-section p {
  margin-bottom: 10px;
  font-size: 2.4rem;
  color: #1a1a1a;
  animation: fadeIn 2s ease-in-out;
  font-weight: 600;
}

.hero-section .coding {
  position: absolute;
  top: 20%;
  right: -40px;
}

.hero-section .highlight {
  color: #5a159e;
}

.mentor-btn, .faq-btn {
  padding: 1.2rem;
  border: none;
  border-radius: 24px;
  margin-top: 30px;
}

.mentor-btn {
  background: #5a159e;
  color: #fff;
  transform: scale(1.1);
}

.hero-section .main-action-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 300px;
}

.hero-section .main-action-buttons button {
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  animation: fadeInUp 1.5s ease-in-out;
  width: 100%;
}

.hero-section .main-action-buttons button:hover {
  background: #ff9500;
}

.hero-section .supporting-text p {
  font-size: 30px;
  font-weight: 200;
  width: 60%;
  text-align: center;
  margin: 80px auto 50px auto;
}

/* Responsive styles */

/* For screens up to 768px */
@media screen and (max-width: 768px) {
  .hero-section h2 {
    font-size: 30px;
  }

  .hero-section p {
    font-size: 1.8rem;
    padding: 0 10px;
  }

  .hero-section .main-action-buttons {
    width: 80%;
  }

  .hero-section .supporting-text p {
    font-size: 1.4rem;
    width: 80%;
  }

  .hero-section .coding {
    position: relative;
    top: 0;
    right: 0;
    max-width: 300px;
    margin-top: 20px;
  }
}

/* For screens up to 487px */
@media screen and (max-width: 487px) {
  .hero-section h2 {
    font-size: 2rem;
  }

  .hero-section p {
    font-size: 1.2rem;

  }

  .hero-section .mentor-btn {
    padding: 1rem;
    font-size: 0.9rem;
    transform: scale(1);
  }

  .hero-section .main-action-buttons {
    width: 60%;
  }

  .hero-section .supporting-text p {
    font-size: 1rem;
    width: 90%;
  }

  .hero-section .coding {
    display: none; /* Hides the image on very small screens */
  }
}





/* Apply general styles for the section */
.Quality {
  text-align: center;
  margin-top: 30px;
}

.Quality h2 {
  font-size: 4.2rem; /* Slightly smaller for mobile */
  margin-bottom: 3.5rem;
  color: #1a1a1a;
  grid-gap: 20px;
  gap: 20px;
}
/* .Quality .mento-meet{
  background:#ff9500;
} */

/* Options container */
.Quality-options {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.Quality .option-btn {
  display: flex;
  justify-content: center;
}

.Quality .option-btn button {
  justify-content: center;
  align-items: center;
  background-color: #5a159e;
  border: none;
  padding: 25px;
  border-radius: 22px;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px;
  margin-bottom: 70px;
  width: 20%; /* Adjusted for smaller screens */
}

.Quality .option-btn button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(90, 21, 158, 0.4);
  background-color: #ff9500;
}

/* Individual card styles */
.option {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  width: 22%;
  min-width: 250px; /* Ensures a reasonable size for cards */
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.option:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.option h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.option h5 {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}


/* Responsive layout for screens up to 489px */
@media (max-width: 489px) {
  .Quality h2 {
    font-size:30px;
    margin-bottom: 1rem;
  }

  .Quality .option-btn button {
    padding: 20px;
    font-size: 0.9rem;
    width: 80%; /* Adjust width for smaller screens */
  }

  .option {
    width: 100%; /* Full width for cards */
    min-width: auto;
    padding: 1rem;
  }

  .option h3 {
    font-size: 1.2rem;
  }

  .option h5 {
    font-size: 0.9rem;
  }
}



/* stats*/
.New-stats-container {
  width: 100%;
  margin-top:40px;
}

.New-stats-container .stats-counts {
  display: flex;
}

/* Flex container adjustments */
.New-stats-container .small-stats-container {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  justify-content: space-around;
  margin-bottom: 60px;
  width: 100%;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

/* Stat card styling */
.New-stats-container .stats-container {
  background-color: #5a159e;
  padding: 18px;
  border-radius: 30px;
  color: white;
  width: 100%;
  margin: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for stat cards */
.New-stats-container .stats-container:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(90, 21, 158, 0.4);
  background-color: #ff9500;
}

/* Inner stat card content */
.New-stats-container .stats-body {
  display: flex;
  padding: 10px;
  justify-content: center;
  text-align:center;
}

/* Title styling */
.stats-body .title {
  font-weight: 700;
  font-size: 50px;
  margin-right: 10px;
  color: #fff;
  flex-shrink: 0;
}

/* Sub-title styling */
.stats-body .sub-title {
  font-size: 30px;
  color: white;
}

/* Responsive layout for screens up to 768px */
@media (max-width: 768px) {
  .New-stats-container .small-stats-container {
    grid-gap: 15px;
    gap: 15px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .New-stats-container .stats-container {
    width: 90%;
    margin: 10px 0;
    padding: 15px;
  }

  .stats-body .title {
    font-size: 24px;
  }

  .stats-body .sub-title {
    font-size: 16px;
  }
}

/* Responsive layout for screens up to 486px */
@media (max-width: 486px) {
  .New-stats-container .small-stats-container {
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }

  .New-stats-container .stats-container {
    width: 100%;
    margin: 5px 0;
    padding: 10px;
  }

  .stats-body .title {
    font-size: 20px;
  }

  .stats-body .sub-title {
    font-size: 14px;
  }

  .purecounter {
    font-size: 1em;
  }
}

/* Keyframe animation */
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}






/*carousel*/

.carousel-container {
  text-align: center;
  margin: 20px;
  font-family: Arial, sans-serif;
  margin-bottom:20px;
}

.carousel-container .main-title {
  font-size: 3.5rem;
  color: #1a1a1a;
  margin-bottom: 40px;
  margin-top:20px;
}

.video-container {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px; /* Space between videos */
  flex-wrap: wrap; /* Wrap videos to the next row on smaller screens */
}

.video-wrapper {
  flex: 1 1 300px; /* Videos will adjust their size to fit the container */
  max-width: 1000px;
}

.mentor-video {
  width: 100%;
  height: 380px; /* Adjust height as needed */
  border-radius: 10px; /* Optional rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  border: none;
}







// works
/* Main Container Styling */
.how-we-work-container {
  margin: 40px auto;
  padding: 20px;
  text-align: center;
  font-family: "Arial", sans-serif;
}

.how-we-work-container h2 {
  font-size: 3.5rem;
  font-weight: bold;
  color: #1a1a1a; /* Purple color */
  margin-bottom: 20px;
  padding-bottom: 10px;
}

/* Grid Container */
.how-we-work-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Always 2 columns */
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
}

/* Card Styling */
.work-card {
  background: linear-gradient(145deg, #f8f9fa, #ffffff); /* Light gradient background */
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px 15px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.work-card:hover {
  transform: translateY(-5px); /* Lift on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Icon Styling */
.work-card .heading {
  font-size: 1.8rem;
  color: #5a159e; /* Purple theme */
  margin-bottom: 0px;
  font-weight:bold;
}

/* Text Styling */
.work-card p {
  font-size: 1.2rem;
  color: #1a1a1a;
  line-height: 1.6;
  font-weight: 200;
}

/* Hover Effect for Icons */
.work-card:hover .icon {
  color: #ff9500; /* Lighter purple on hover */
}

/* Provide Container Styling */
.provide-container {
  margin: 20px auto;
  padding: 20px;
  text-align: center;
}
.mento-meet {
  color: #ff9500;
}


.provide-container h2 {
  font-size: 3.5rem;
  font-weight: bold;
  color: #1a1a1a;
  border-bottom: 2px solid #5a159e;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

/* Item Container for the Grid */
.item-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Default grid for large screens */
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
}

/* Individual Key Item */
.key-item {
  display: flex;
  flex-direction: column; /* Ensure items stack vertically */
  align-items: center;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden; /* Ensure no overflow */
}

/* Image Styling */
.key-item img {
  width: 100%;
  height: 200px;
  object-fit: contain; /* Fit within the container */
  border-radius: 8px;
  background-color: white;
}

/* Heading Styling */
.key-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin: 10px 0;
}

/* Paragraph Styling */
.key-item p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

/* Hover Effect for Key Items */
.key-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background: #ff9500;
}

/* Responsive Adjustments for Screens up to 768px */
@media screen and (max-width: 768px) {
  .provide-container h2 {
    font-size: 2.5rem;
  }

  .item-container {
    grid-template-columns: 1fr 1fr; /* Adjust grid to 2 columns */
    grid-gap: 15px;
    gap: 15px;
  }

  .key-item {
    padding: 15px;
  }

  .key-item img {
    height: 150px; /* Reduce image size */
  }

  .key-item h3 {
    font-size: 1.3rem;
  }

  .key-item p {
    font-size: 0.95rem;
  }
}

/* Responsive Adjustments for Screens up to 487px */
@media screen and (max-width: 768px) {
  .provide-container h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .item-container {
    grid-template-columns: 1fr; /* Single-column layout */
    grid-gap: 10px;
    gap: 10px;
  }

  .key-item {
    padding: 10px;
  }

  .key-item img {
    height: 120px; /* Further reduce image size */
  }

  .key-item h3 {
    font-size: 1.1rem;
  }

  .key-item p {
    font-size: 0.85rem;
  }

  .hero-mentor-section .main-action-buttons {
    margin: 0px;
  }
  .hero-mentor-section p{
    justify-content:center;
    text-align:center;
  }

  .hero-mentor-section .supporting-text p {
    margin: 20px;
  }

  .Quality {
    margin-top: 10px;
  }

  .Quality-options {
    flex-direction: column;
    align-items: center;
  }

  .Quality-options .option {
    width: 90%;
    margin-left: 10px;
  }

  .Quality h2 {
    font-size: 30px;
  }

  .Quality .option-btn button {
    width: 70%; /* Adjust width for medium screens */
    margin-bottom: 5px;
  }

  .New-stats-container {
    width: 80%;
  }

  .how-we-work-container h2 {
    font-size: 30px;
    width: 80%;
    margin: 10px auto;
  }

  .how-we-work-grid {
    grid-template-columns: 1fr; /* Stacks into 1 column for smaller devices */
  }
  .work-card .icon {
    font-size: 2.5rem;
  }

  .work-card p {
    font-size: 0.9rem;
  }

  .Quality-options .option {
    margin-left: 10px;
  }
  .Quality-options .option h3 {
    font-size:25px;
  }
  .carousel-container .main-title {
    font-size:30px;
  }
 
}



body{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      margin-top: 0px;
}
#head{
    text-align: center;
    color:black;
    font-style:bold;
	width:100%;
	font-family:Montserrat;
}



h1{
   margin-top:0px;
   font-size: 3.6rem;
   font-weight:900;
   font-family:Montserrat;
   text-shadow: 7px 7px #998d8d82;
}

.slider-area{
background: #F5F5F5;
   padding: 0px 0;
   position: relative;
   margin: 20px 0px;
}

.carousel-item{
		background-color:white;
}

.carousel-control.left,
.carousel-control.right{
   background: #43D5B5;

}

.img-area{
    width: 180px;
    height: 180px;
    margin: auto ;
    /*padding: 2px;*/
    border: 5px solid #fff;
    border-radius: 100%;
    overflow:hidden;
}

.img-area img{
width: 100%;
}

.carousel-caption{
   position: static;
   padding-bottom: 15px;
   padding-top: 0;
   font-weight:300;

}
.carousel-indicators li{
   background-color:black;
}
.carousel-caption h3{
   font-size: 26px;
   margin-bottom: 15px;
   margin-top: 25px;
   color: #FFB13E;
   font-weight:300;
}
.carousel-caption h4{
   color: #43D5B5;
	font-weight:300;
}
.carousel-caption p{
   font-size: 18px;
   margin: auto;
   width: 70%;
   margin-bottom: 10px;
   color: #212529;
	font-weight:300;
}

.carousel-caption p span i{
	color:#ffc107;
	font-size:30px;
	padding-left:10px;
	padding-right:10px;
}

.carousel-indicators{
   bottom: -35px;
   color: #808080;
}

 @media screen and (max-width: 850px) {
	 #head{
		 font-size:20px;
	 }
	 
	 .carousel-caption p{
		 width:100vw;
	
	 }
 }
 
 
  @media screen and (max-width: 500px) {
		 #head{
			 font-size:30px;
		 }
		 
		 .carousel-caption p{
			 width:90vw;
		 }
  }
.App {
  text-align: center;
}




.card-img-top {
  width: 100%;
  height: 17vw;
  object-fit: cover; 
  
} 
 
h1{
  font-size: 60px; 
  font-family:Georgia, 'Times New Roman', Times, serif
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.updated,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

:root{
    --primary: rgb(0, 0, 0);
}

#HOMEPAGE .btn{ 
    display: inline-block;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 50px;
    min-width: 250px;
    min-height: 50px;
	text-decoration:none;
	font-weight:550;
}

#HOMEPAGE .btn--outline{
    position: relative;
    background-color: #5A159E;
    color:white;
    border: 1px solid #5A159E;
    transform: all 0.3s ease-out;
}
#HOMEPAGE .btn--join1{
    position: relative;
    background-color: transparent;
    -webkit-print-color-adjust: var(--primary);
            color-adjust: var(--primary);
    
    border: 5px solid var(--primary);
    border-radius: 100px;
    transform: all 0.3s ease-out;
    
}
#HOMEPAGE .btn--login1{
        background-color: transparent;
        -webkit-print-color-adjust: var(--primary);
                color-adjust: var(--primary);
        
        padding: 8px 20px;
        border: 1px solid var(--primary);
        transform: all 0.3s ease-out;
        
    }
#HOMEPAGE .btn--medium{
    padding: 20px 20px;
    font-size: 23px;
}
#HOMEPAGE .btn--login{
    padding: 8px 20px;
    font-size: 20px;
}
#HOMEPAGE .btn--large{
    
    font-size: 2opx;
}
#HOMEPAGE .btn--join{
    left: -80px;
    border-color: orange;
    font-size: 20px;
    border-width:3px;
    padding-top: 15px;
    padding-bottom: 15px;
    
}

#HOMEPAGE .btn--large:hover,#HOMEPAGE .btn--medium:hover,#HOMEPAGE .btn--login:hover{
    color: #5A159E;
    background: white;
    transition:all 0.3s ease-out;
    border:1px solid #5A159E;
}

#HOMEPAGE .btns-active{
	background:#fa9c09 !important;
    color: white !important;
    border:none;
}
  


@media screen and (max-width: 1500px) {
    

   #HOMEPAGE .btn--login{
        left: -110px;
        font-size: 20px;
        top: 100px;
    }

    #HOMEPAGE .btn--login1{
        background-color: transparent;
        -webkit-print-color-adjust: var(--primary);
                color-adjust: var(--primary);
        
        padding: 8px 20px;
        border: 1px solid var(--primary);
        transform: all 0.3s ease-out;
    
    }
    #HOMEPAGE .btn--join{
        left: 0px
    }



}

.hero-new-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white !important;
  background-repeat: no-repeat;
  background-position: right, right;
  background-size: 40%, 60%;
  background-position-x: 55vw, 50vw;
  background-position-y: 40vh, -50vh;
  padding: 20px;
  animation: fadeIn 1.5s ease-in-out;
  width: 100%;
}

.hero-new-container .main-title {
  position: relative;
  font-size: 4.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  animation: slideInTop 1s ease-in-out;
  margin-top: 20px;
  color: #1a1a1a;
}

.hero-new-container .main-title .looking {
  color: #5A159E;
}


.hero-new-container .abstract-line {
  margin-bottom: 10px;
  position: absolute;
  top: -15px;
  left: -37px;
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 30px;
  animation: fadeIn 2s ease-in-out;
  margin-top: -10px;
}
.btn1, .btn2 {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 24px;
}

.hero-new-container .hero-btns button{
  padding: 15px;
  width: 200px;
}

.hero-new-container .hero-btns .btn1 {
  background: #5a159e;
  color: #fff;
  transform: scale(1.1);

}

.hero-new-container .hero-btns .btn2 {
  background: #ff9500;
  color: #fff;
  transform: scale(1.1);
}
.hero-new-container .hero-btns {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0px;
  margin-top: 30px;
  radius: 2rem;
  padding: 30px;
  justify-content: center;
  align-items: center;
  grid-gap: 40px;
  gap: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  animation: fadeInUp 1.5s ease-in-out;
  width:100%;
}

.hero-new-container h3 {
  color: #1a1a1a;
  font-size: 2.8rem;
  padding-top: 0px;
  margin-top: 10px;
  text-align: center;
  font-weight: 550;
}

.hero-new-container p {
  color: #1a1a1a;
  font-size: 25px;
  width: 70%;
  text-align: center;
}


.hero-new-container .hero-home-right {
  margin-top: 20px;
  animation: fadeInRight 2s ease-in-out;
  svg {
    position: absolute;
    right: 0;
    top: 340px;
    max-width: 100%;
    height: auto;
  }
}

/* Default styles */
/* Fade-in animation for the container */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation applied to the container */
.hero-new-container .small-stats-container {
  display: flex;
  flex-wrap: wrap;
 colun-gap: 20px;
 grid-row-gap: 5px;
 row-gap: 5px;
  justify-content: space-around;
  margin-top: 260px;
  margin-bottom: 10px;
  width: 90%;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

/* Stat card styling */
.hero-new-container .stats-container {
  background-color: #5a159e;
  padding: 18px;
  border-radius: 30px;
  color: white;
  /* width: 100%; */
  margin: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for stat cards */
.hero-new-container .stats-container:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(90, 21, 158, 0.4);
  background-color:#ff9500;
}

/* Inner stat card content */
.hero-new-container .stats-body {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
}

/* Title styling */
.stats-body .title {
  font-weight: 700;
  font-size: 30px;
  margin-right: 10px;
  color: #fff;
  flex-shrink: 0;
}

/* Sub-title styling */
.stats-body .sub-title {
  font-size: 30px;
  color: white;
}


@media (max-width: 768px) {
  .hero-new-container .small-stats-container {
    grid-gap: 15px;
    gap: 15px;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .hero-new-container .stats-container {
    width: 90%;
    margin: 10px 0;
  }

  .stats-body .title {
    font-size: 24px;
  }

  .stats-body .sub-title {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .hero-new-container .small-stats-container {
    grid-gap: 10px;
    gap: 10px;
    margin-top: 200px;
  }

  .hero-new-container .stats-container {
    width: 100%;
    margin: 5px 0;
  }

  .stats-body .title {
    font-size: 22px;
  }

  .stats-body .sub-title {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .hero-new-container .small-stats-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .hero-new-container .stats-container {
    width: 95%;
    padding: 10px;
    margin: 5px 0;
  }

  .stats-body .title {
    font-size: 10px;
  }

  .stats-body .sub-title {
    font-size: 12px;
  }

  .purecounter {
    font-size: 1em;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

/* Responsive CSS starts here */
@media screen and (max-width: 1000px) {
  .hero-new-container h2 {
    font-size: 3.5rem;
  }

  .hero-new-container h3 {
    font-size: 2.2rem;
  }

  .hero-new-container p {
    font-size: 20px;
    width: 80%;
  }


}

@media screen and (max-width: 768px) {
  .hero-new-container h2 {
    font-size: 8rem;
  }

  .hero-new-container h3 {
    font-size: 1.8rem;
  }

  .hero-new-container p {
    font-size: 18px;
    width: 85%;
  }

  .hero-new-container .hero-home-right svg {
    width: 350px;
    height: 350px;
  }
}

@media screen and (max-width: 576px) {
  .hero-new-container h2 {
    font-size: 8rem;
  }

  .hero-new-container h3 {
    font-size: 1.6rem;
  }


  .hero-new-container .hero-home-right svg {
    width: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 768px) {
  .hero-new-container h2 {
    font-size: 6rem;
  }

  .hero-new-container h3 {
    font-size: 1.4rem;
  }

  .hero-new-container .hero-home-right {
    display: none;
  }

  .stats-body .title {
    font-size: 2.5rem;
  }

  .stats-body .sub-title {
    font-size: 1.8rem;
  }
}


@media screen and (max-width: 769px) {
  .hero-new-container .hero-btns  {
    margin-bottom: 10px;
  }
  .hero-new-container .main-title svg {
    display: none;
  }
  .hero-new-container .main-title span {
    font-size: 30px;
  }
  .hero-new-container .sub-main-title {
    font-size: 20px;
  }
}



.text-container{font-size:2rem;font-family:Arial,sans-serif;border-right:2px solid #5a159e;white-space:nowrap;overflow:hidden;color:#5a159e;font-weight:600;animation:typing 3s infinite,blink 1s step-end infinite alternate}@keyframes typing{0%,100%{width:0}50%{width:350px}}@keyframes blink{from{border-right-color:rgba(0,0,0,0)}to{border-right-color:#5a159e}}@media screen and (max-width: 768px){.text-container{font-size:30px}}
.care-container {
	text-align: center;
	padding: 50px 20px;
	background-color: #f4f4f9;
	font-size:1.8rem;
  }
.care-container .grid-container {
	grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 900px){
	.care-container .grid-container {
		grid-template-columns: 1fr;
	}	
}
.care-container img{
		margin-right:0px;
		margin-top:50px;
		width:100px;
		height:100px;
	}


.care-container .row{
	/* margin-left:10px;
	margin-right:10px; */
}


.rounded{
	position:absolute;
	right:0;
	z-index:100;
}

.care-container .card{
			margin:0 auto !important;
			max-width:250px;
			min-width:220px;
			padding-bottom:38px;
			
}
		
.care-container  h2{
	width:100%;
    font-weight:600;
    font-size:3.2rem;
	justify-content:center;
	align-items:center;
	color:#1a1a1a;
}
.care-container p {
	font-size: 1.2em;
	color: #1a1a1a;
	margin-bottom: 40px;
  }


@media screen and (max-width: 1000px) {
	      .care-container .tagline{
			font-weight:bold;
			text-align:end;
			font-size:30px;
			position:absolute;
			top:60px;
			right:15px;
			text-shadow:none;
		}
		
		
		
		.rounded{
			display:none;
		}
			
			.care-container .hero-btns{
		margin:0px 0px !important;
	}
	
	.care-container .hero-btns{
		display:flex;
		flex-wrap:wrap;
		min-width:100vw;
		align-items:center;
	}
	
	.care-container .hero-btns{
		margin:0px !important;
	}
	
	.care-container .hero-btns .btn-mobile{
		margin:15px auto !important;
	}
	
	.care-container .hero-btns a{
			max-height:30px;
	}
}

@media screen and (max-width: 479px) {
	.care-container .card{
		min-width:270px;
	}

	.care-container .hero-btns{
		margin:0px 0px !important;
	}
	
	.care-container .hero-btns{
		display:flex;
		flex-wrap:wrap;
		min-width:100vw;
		align-items:center;
	}
	
	.care-container .hero-btns{
		margin:0px !important;
	}
	
	.care-container .hero-btns .btn-mobile{
		margin:15px auto !important;
	}
	
	.care-container .hero-btns a{
			max-height:30px;
	}
	
	.care-container .hero-btns  .btn{
		min-width:auto !important;
	}
	
	.care-container img{
		margin-left:0px;
		margin-top:8px;
		width:100px;
		height:150px;
		background:white;
	}
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
	grid-gap: 20px; 
	gap: 20px;
	padding: 20px;
  }
  
  .grid-item {
	display: inherit;
	justify-content: space-between;
    align-items:center;
	background-color: #f9f9f9;
	padding: 20px;
	border-radius: 8px;
	height: 240px;
	/* min-height: 300px;  */
	width: 100%; 
	font-size:1.2rem;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .grid-item:hover {
	transform: translateY(-10px);
	box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  }
  .grid-item h3 {
	font-size: 30px;
	margin-bottom: 5px;
	color: #1a1a1a;
	font-weight:600;
	text-align:left;
	margin-top:50px;
  }
  .grid-item h3,
  .grid-item p {
	margin-top: 0;
	padding: 0;
  }
  
  .grid-item .text-content {
	flex: 1 1;
  }
  
  .grid-item button {
	margin-top: 15px;
	padding: 10px 15px;
	background-color: #5a159e;
	color: white;
	border: none;
	border-radius: 15px;
	cursor: pointer;
	font-size:1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align:left;
	margin-left: 0;
	font-size:1.2rem;
  }
  
  .grid-item button:hover {
	background-color: #FF9500;
	transform: translateY(-3px);
	
  }
  
  .grid-item svg,
  .grid-item PersonalizedImg {
	margin-right: 20px;
	flex-shrink: 0;
  }
  
 
  
  .flexBox {
	display: flex;
  }

  .flexJustifySpaceBetween {
	justify-content: space-between;
  }

  .flexGrow {
	flex-grow:1;
  }
  .info-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
  }
  
  .info-container div {
	flex: 1 1;
	text-align:left;
	margin-left: 0;
  }
  
  .info-container p {
	font-size: 1.2rem;
	color: #1a1a1a;
	text-align:left;
  }

  .info-container img {
	margin: 0px;
  }

  .care-container .offer-container {
	border: 1px solid rgb(90, 21, 158);
    padding: 20px 20px; 
    border-radius: 10px;
  }
  @media screen and (max-width: 768px) {
	.care-container {
	  padding: 10px;
	}
  
	.care-container h2 {
	  font-size: 1.8rem;
	  margin-bottom: 15px;
	  margin-top:20px;
	}
  
	.care-container p {
	  font-size: 1rem;
	  margin-bottom: 20px;
	}
  
	.offer-container h2 {
	  font-size: 40px;
	}
  
	.grid-item {
	  padding: 15px;
	  height: auto;
	}
  
	.info-container {
	  flex-direction: column; /* Stack text and SVG vertically */
	  align-items: flex-start;
	}
  
	.info-container p {
	  font-size: 0.9rem;
	}
  
	.info-container button {
	  padding: 8px 15px;
	  font-size: 0.9rem;
	  margin-top: 10px;
	}
  
	/* svg {
	  margin-top: 10px;
	  width: 100px !important; 
	  height: 100px !important;
	} */
  }

  @media screen and (max-width: 479px) {
	.navbar-toggler-container {
		display: block;
	}
	#navbarSupportedContent {
		display: none;
	}
	.navbar-collapse.show #navbarSupportedContent {
		display: block;
	}
  }

  @media screen and (min-width: 478px) {
	.navbar-toggler-container {
		display: none;
	}
	#navbarSupportedContent .navbar-nav {
		flex-direction: row;
	}
  }

  #navbarSupportedContent a {
	color: black;
  }

  #navbarSupportedContent a:hover{
	color: #5a159e;
  }

  #mobileNavigation #navbarSupportedContent .navbar-nav {
	flex-direction: row;
	justify-content: start;
	flex-wrap: wrap;
  }
  .care-container .offer-container .container-btn button{
	justify-content:center;
	align-items:center;
	background-color:#5a159e;
	border:none;
	padding:30px;
	border-radius:22px;
	color:white;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .care-container .offer-container .container-btn button:hover{
	transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(90, 21, 158, 0.4);
    background-color:#ff9500;
  }

  
  @media screen and (max-width: 769px) {
	.care-container .offer-container {
		padding: 10px 0 0 0;
	}
	.grid-container {
	  grid-template-columns: 1fr;
	 grid-gap: 20px;
	 gap: 20px;
	}
  }


  .navbar-nav {
	justify-content: flex-end;
  }


  .hero-main-container{ 
    width: 100%;
	height:auto;
    display:grid;
	background-color:white !important;
    grid-template-columns: auto auto;
    background: url(/static/media/Path-5.a1ce3c83.svg);
    background-repeat: no-repeat;
    background-position:right;
    background-position-x: 92vw;
    background-position-y: 10vh;
	padding-top:0px;
  margin-top:-20px;
  margin-bottom:70px;
  }
  
  .hero-main-container .join-our{
	    margin-left:0px;
  }

  .hero-main-container .join-our button{
	  min-width:400px;
	
  }

  .hero-main-container h3{
    font-size: 80px;
    color: rgb(230, 120, 31);
	text-align:center;
	padding-top:100px;
  }
  
.hero-main-container h2{
  font-size: 100px;
    padding-top: 0px;
    padding-left: 8vw;
    color: rgb(0, 0, 0);
	font-weight:bold;
}

  .hero-main-container .btn1{
      padding-left: 0px;
      
  }
 .hero-main-container .btn2{
    padding-left: 140px;
}

	.hero-main-container .herd-container{
		align-self:center;
	}

  .her-container > h2 {
    color: rgb(230, 120, 31);
    font-size: 130px;
    justify-content: left;
    margin-top: 0vh;
    margin-left: 8vw;
    text-shadow: 4px 4px #998d8d;
  }
  
  .her-container > h3 {
    color: rgb(0, 0, 0);
    font-size: 80px;
    justify-content: left;
    margin-top: 30vh;
    margin-left: 8vw;
  }

  .her-container > p {
    margin-top: 8px;
    color: rgb(0, 0, 0);
    font-size: 25px;
    margin-left: 8vw;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .her-btns {
    margin-top: 20vh;
    margin-left: 0vw;

  }
  
  .her-btns .btn {
    margin: 10px 30px;
  }

  .fa-users{
    font-size: 45px;
    position:absolute;
    left:-1px;
    top:-1px;
    padding: 2px;
    border:solid 4px orange; 
    border-radius: 100%;
  }


  @media screen and (max-width: 1300px) {

    .hero-main-container { 
      width: 100%;
      display:grid;
      grid-template-columns: auto;
      align-items: center;
	 height:auto;
	 margin-top:30px;
	 margin-bottom:20px;
    }
	
    .hero-main-container h3{
      font-size: 70px;
	  margin-top:0px;
    }
	
    .hero-main-container h2{
      font-size: 80px;
	  padding:0;
	  text-align:center;
    }
	
	 .her-btns{
		margin:0 auto;
		display:block;
	}
		

  }

 
  
  
  @media screen and (max-width: 900px) {
	.hero-main-container{
		margin-top:100px;
		margin-bottom:20px;
	}

	.hero-main-container  h3{
		  font-size:40px;
		  padding-top:30px;
	  }
	  
	 .hero-main-container h2{
		  font-size:25px;
	  }
	  
	.btn-mobile{
		display:flex !important;
		flex-wrap:wrap;
	}
	
	.her-btns .btn1{
		display:block ruby;
	}
	

  }
  
  @media screen and (max-width: 500px) {
	  
	  .hero-main-container{
		  padding-top:0px !important;
		margin-top:120px;
		margin-bottom:10px;
	}
	
	 .hero-main-container h3{
		 padding-top:20px;
	 }
	  
	  .her-btns .btn2{
		padding-left:0px;
		}
		
		 .her-btns button{
				min-width:130px !important;
		}
		
		.her-btns .btn1{
			margin-left:-20px;
		}
	
  }
  
  
.grid-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);; 
	grid-gap: 20px;; 
	gap: 20px;
	padding: 20px;
 
  }
  
  .key-item {
	display: block;
	justify-content: center;
    align-items:center;
    text-align:center;
	/* background-color: #f9f9f9; */
   padding:20px;
	border-radius: 8px;
	/* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
	min-height: 300px; 
	width: 100%; 
	
  }
  
  .key-item h3 {
    margin-top: 10px;
    padding: 0;
    font-weight:bold;
    font-size:25px;
  }
  .key-item p {
	margin-top: 10px;
	padding: 0;
  font-weight:200;

  }
  
  .key-item .text-content {
	flex: 1 1;
  }
  .key-item button{
    background-color:#FF9500;
    padding:25px;
    border:none;
    color:white;
    border-radius:32px;
    margin-top:60px;
    width:100%;
    font-size:30px;
  }

  .functions-container {
      margin: 10px 32px;

  }

  .functions-container h2{
    text-align:center;
    font-size:55px;
    font-weight:600;
    animation: fadeIn 1s ease-in-out;
    border-bottom:1px solid #5a159e;
    margin-bottom:20px;
  }
  .key-item p:hover{
    color:white;
  }
  
  @media screen and (max-width: 576px) {
    .grid-container {
      grid-template-columns: 1fr;
      grid-gap: 15px;
      gap: 15px;
    }
    .key-item {
      padding: 15px;
    }
  }
  
  /* Media Query for devices with width <= 769px */
  @media screen and (max-width: 769px) {
    .grid-container {
      grid-template-columns: 1fr; /* 1 column on very small devices */
      grid-gap: 10px;
      gap: 10px;
    }
    .key-item {
      padding: 10px;
    }
    .key-item h3 {
      font-size: 1.2rem; /* Reduce heading size */
    }
    .key-item p {
      font-size: 0.9rem; /* Reduce paragraph size */
    }
    .key-item p:hover{
      color:white;
    }
  
    .functions-container h2 {
      font-size: 30px; /* Reduce the main heading size */
    }
  }
.top-mentors-container {
	margin: 100px 32px; 
	padding: 50px; 
	display: flex;
	justify-content: center; 
	align-items: center; 
	background-color: #5a159e;
	border-radius: 20px;
  }
  
  .top-mentors-body {
	display: block;
	width: 200%;
    color:white;
  }
  .top-mentors-body h2 {
	color: white;
	font-weight: 500;
	text-align: center; 
  font-size:4.5rem;
  margin-bottom:10px;
  }
  
  .top-mentors-body p {
	color: white;
	text-align: center; 
  font-size:30px;
  margin-bottom:20px;
  }
  
  
  .top-mentors-body .mentor-name {
	  font-size: 20px;
    color: #5a159e;
    text-align:center;
    margin-top: 18px;
    margin-bottom: 0px;
    font-weight:600;
  }
  
  .top-mentors-body .mentor-role {
	  font-size: 12px;
    color: black;
    margin-top: 5px;
    text-align:center;
    margin-bottom: 0px;
  }

  .top-mentors-body .mentor-card-container{
    display:flex;
    justify-content:center;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
  }

  .top-mentors-body .mentor-card-container .mentor-category {
    background-color: white;
    border-radius: 30px;
    padding: 10px;
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .top-mentors-body .mentor-card-container .mentor-category:hover{
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .top-mentors-body .mentor-card-container .mentor-category p {
    color:black;
    margin-bottom: 0;
    font-size:25px;
  }

  .top-mentors-body .mentor-card-container .mentor-card{
     background-color:white;
     width:200px;
     height:220px;
     border-radius:12px;
     position: relative;
  
  }
  .top-mentors-body .mentor-card-container .mentor-card .orange{
    background-color:orange;
    height:80px;
    border-color: transparent;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

  .top-mentors-body .mentor-card-container .mentor-card img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: white;  
    justify-content:center;
    margin-left:60px;
    margin-top:-40px;
  }

  .top-mentors-body .mentor-btns{
    display:flex;
    justify-content:space-around;
    grid-gap:60px;
    gap:60px;
    background-color:#5a159e;
  }

  @media screen  and (max-width: 768px) {
    .top-mentors-body .title {
      font-weight: 400;
      font-size: 35px;
    }
    .top-mentors-body .sub-title {
      font-size: 18px;
      margin: 25px 0px;
    }
  }

  .top-mentors-body .mentor-card:hover{
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  } 

.profile-section{
	margin-top:85px
}

.profile-section .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin: 3vh 3vw;
    background:white;
  }
  
 .profile-section .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .profile-section .container1 {
    padding: 2px 16px;
    display: flex;
    padding-top: 15vh;
    padding-left: 10vw;
    flex-wrap:wrap;
    
  }
  
  .profile-section  .container1 img{
	  width:200px;
  }
  
   .profile-section  .container1 .image{
	   width:100%;
   }
  
  .profile-section .stars{
      padding-left: 30px;
      padding-top: 10px;
  }
  .profile-section .fa-star{
      padding-left: 10px;
      color: orange;
  }

  b{
      padding-left: 30px;
      font-size: 20px;
     
  }

  .profile-section .qualify{
      padding-left: 25px;
      padding-top: 10px;
  }

  .profile-section  .achieve{
      list-style-type: none;
  }

  .profile-section  .span{
      padding-left: 10px;
  }

  .profile-section .name-des{
      padding-left: 100px;
      padding-top: 15px;
  }

  .profile-section .name{
      font-size: 40px;
      font-weight: bold;
	  text-align:left;
  }
  
  .profile-section .desig{
    font-size: 20px;
    color: grey;
	text-align:center;
  }

  .profile-section .achieved{
      padding-top:40px;
  }
  
  .profile-section .achieve{
      font-size: 20px;
  }
  
  .profile-section li{
	padding-top: 8px;
  }

  .profile-section .handle{
      padding-left: 8vw;
      padding-top: 10px;
      font-size: 50px;
  }
  
  .profile-section .handle .fa-instagram,.profile-section .handle .fa-linkedin{
	  background-color:white;
	  padding:5px;
	  color:black;
  }
  
 .profile-section  a{
      padding-right: 2vw;
      color: rgb(0, 0, 0);
  }

  .profile-section.percent{
      padding-left: 10vw;
  }

  .profile-section span1{
      color: orange;
      font-size: 20px;
  }
  
  @media screen and (max-width:900px){
	  .profile-section .card {
		margin:10px 10px;
	  }
	  
	  .profile-section .container1 {
			padding: 10px 15px;
			display: flex;
			flex-wrap:wrap;
			justify-content:center;
	  }
	  
	   .profile-section .container1 img{
		   height:50%;
	   }
	   
	     .profile-section .name-des{
		  padding-left: 0px;
		  padding-top: 5px;
	  }
	  
  }
.discover-container {
	margin: 100px 32px; 
	padding: 50px; 
	background-color: #5a159e;
	border-radius: 20px;
  }

  .discover-container .title {
	font-size: 3.5rem;
	color: white;
	text-align:center;
	margin-bottom: 50px;
	font-weight:500;
  }
  .title .mento-meet {
	color: #ff9500;
  }
  
    
  .discover-body {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	grid-gap: 30px;
	gap: 30px;
	font-size:1.2rem;
  }
  ul.custom-bullets li {
    list-style: none; /* Remove default bullets */
    position: relative; /* Position for ::before element */
    padding-left: 20px; /* Space for the custom bullet */
  }

  ul.custom-bullets li::before {
    content: "•"; /* Custom bullet symbol */
    position: absolute;
    left: 0;
    color: white; /* Custom bullet color */
  }


  .discover-body li {
	font-size: 20px;
	color: white;
	font-weight: 200;
	text-align:left;
	letter-spacing: 1px;
	margin-bottom: 8px;
	width:40vw;
	justify-content: center;
  }

 
  @media screen and (max-width: 768px) {
	.discover-container {
		margin-top: 30px;
		padding: 20px;
	}
	.discover-container .title {
	  font-size: 30px;
	}
  
	.discover-body li {
	  font-size: 1.1rem;
	  line-height: 1.5;
	}

	ul.custom-bullets li {
		width: 100%;
	}
  
	/* YouTube video adjustments */
	iframe {
	  width: 100%;  /* Make YouTube video responsive */
	  height: auto;

	}
  }

/* testimonial.css */

.testimonials {
  text-align: center;
  margin-bottom:30px;
}

.testimonials h2 {
  font-size: 4rem;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
  font-weight:600;
}

.testimonials h3 {
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 2rem;
  margin-top:20px;
}

.testimonial-cards {
  display: flex;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 0.8s ease-in-out forwards;
  animation-delay: 0.2s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.testimonial-card {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 1.5rem;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.testimonial-card p {
  font-size: 0.95rem;
  color: #333;
  line-height: 1.5;
  margin-bottom: 2rem;
  font-style: italic;
}

.testimonial-card h4 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #1a1a1a;
  display:inline;
}


@media screen and (max-width: 768px) {

  .testimonials h2 {
    font-size: 30px;
  }

  .testimonials h3 {
    font-size: 1.3rem;
  }
}
.collaboration {
  background-color: #f4f4f4;
  padding: 50px 20px;
  text-align: center;
}

.collaboration h2 {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 30px;
}
.collaboration .option h3 {
  font-size:1.2 rem;
}

.collaboration h2 span {
  color: #5a159e;
}

.collaboration-options {
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  margin: 40px 0;
}

.option {
  background-color: #5a159e;
  padding: 20px;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width:80%;
  max-width: 100%;
  text-align: left;
}

.option:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.option h3 {
  font-size: 2rem;
  margin-bottom: 15px;
  font-weight:600;
  color:#ffff;
  justify-content:center;
  text-align:center;
}

.option h5 {
  font-size: 1.1em;
  margin-bottom: 20px;
  color: #ffff;
  justify-content:center;
  text-align:center;
}

.option button {
  background-color: #5a159e;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.option button:hover {
  background-color: #FF9500;
  transform: translateY(-5px);
}

.collaboration h2:last-of-type {
  font-size: 2.5em;
  margin-top: 30px;
  margin-bottom: 30px;
}

.collaboration h2 span {
  color: #FF9500;
}

.contact-us {
  background-color: #5a159e;
  color: white;
  padding: 20px 40px;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-us:hover {
  background-color: #FF9500;
  transform: translateY(-5px);
}

/* Animation for options appearing on load */
.option {
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 0.8s ease forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
}
}
/* Responsive Styling */
@media (max-width: 768px) {
  .collaboration h2 {
    font-size: 30px;
  }
  .collaboration-options {
    flex-direction: column;
    align-items: center;
  }

  .option {
    width: 100%;
    margin-bottom: 20px;
  }

  .collaboration h2:last-of-type {
    font-size: 2rem;
  }
}

/* FAQ.css */
.faq {
    background-color: #5a159e;
    padding: 60px 20px;
    margin: 0 auto;
  }
  
  .faq h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: white;
  }
  .faq p {
    color:white;
    text-align:center;
    font-size:1.5rem;
    margin-bottom: 20px;
  }
  
  .faq-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    /* transition: all 0.3s ease; */
    cursor: pointer;
    /* overflow: hidden; */
    /* max-height: 0; */
    transition: height 0.4s ease-out;
    text-align:left;
  }

  
  .faq-item h3 {
    font-size: 1.5rem;
    margin: 0;
    color: #333;
    position: relative;
  }
  
  .faq-item h3:hover {
    color: #5a159e;
  }
  
  .faq-item p {
    margin-top: 15px;
    font-size: 1.1rem;
    color: #555;
  }
  
  .faq-item h3:after {
    content: "+";
    position: absolute;
    right: 10px;
    font-size: 1.5rem;
    color: #007bff;
    transition: transform 0.3s ease;
  }
  
  .faq-item.active h3:after {
    content: "-";
    transform: rotate(180deg);
  }
  
  .faq-item.active p {
    display: block;
  }

.faq-container {
  text-align:center;
}
.faq-container button{
  padding:10px;
  background-color:#FF9500;
  border-radius:32px;
  border:none;
  padding:20px;
  color:white;
}
/* Footer container */
.footer {
  background-color: #1c1c1c;
  color: #fff;
  padding: 40px 20px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

/* .brand-social .navbar-logo {
	height:50px;
	width:50px;
	margin-left:20px;
  } */
.footer-brand-logo {
  width: 300px;
  height: 70px;
  margin-left: -50px;
  /* margin-right: 200px; */
  /* margin-top:-70px; */
}
/* Brand and Social Media */
.brand-social {
  flex: 1 1;
  /* min-width: 250px; */
}
.brand-social .title-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -30px;
}

.brand-social .web-title {
  font-size: 2rem;
  font-weight: 600;
}

.social-icons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-left: -40px;
  /* margin-top:-50px; */
}

.social-icons a {
  color: #fff;
  font-size: 2.1rem;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #f0ad4e;
}

/* Support Section */
.support {
  flex: 1 1;
  min-width: 200px;
}

.support h4 {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.support ul {
  list-style-type: none;
  padding: 0;
}

.support ul li {
  margin-bottom: 10px;
}

.support ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.support ul li a:hover {
  color: #f0ad4e;
}

/* Contact Section */
.contact {
  flex: 1 1;
  min-width: 250px;
}

.contact h4 {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.contact p {
  margin-bottom: 10px;
}

.contact i {
  margin-right: 10px;
}
.contact p:hover {
  color: #f0ad4e;
}
/* Raise Complaint */
.complaint {
  flex: 1 1;
  min-width: 250px;
}

.complaint h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.complaint form {
  display: flex;
  flex-direction: column;
}

.complaint input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  color: #fff;
}

.complaint button {
  padding: 10px;
  background-color: #f0ad4e;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.complaint button:hover {
  background-color: #d48b34;
}

.telegram-link {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.telegram-link i {
  margin-right: 10px;
}

.telegram-link i:hover {
  color: #f0ad4e;
}
.footer-content {
  display: inline;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 10px;
}

.footer-last {
  border-top: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap:wrap;
  width: 100%;
}
.footer-center-text {
  font-weight:bold;
}


.footer-last ul {
  display:flex;
  margin: 10px;
  justify-content: center;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  text-align: center;
  font-size: 18px;
  flex-wrap: wrap;

}

.footer-last ul li {
  cursor: pointer;
  padding-right: 10px;
  border-right : 1px solid white;
}

.footer-last ul li:last-child {
  border-right: none;
}
/* Default (Desktop View) */
.desktop-text {
  display: inline;
}
.mobile-text {
  display: none;
}

/* Mobile View (up to 768px) */
@media (max-width: 768px) {
  .desktop-text {
    display: none;
  }
  .mobile-text {
    display: inline;
  }
}


/* Responsive design */
@media (max-width: 768px) {
  .footer {
    padding-left: 60px;
  }
  .footer-container {
    flex-direction: column;
  }

  .footer-container .navbar-logo {
    margin-left: 65px;
  }

  .footer-container > div {
    margin-bottom: 20px;
  }

  .footer-container .social-icons {
    margin-left: 0px;
  }

  .footer-last ul  {
    margin: 0px;
  }
  .footer-brand-logo {
    width:200px;
    height:50px;
    margin-left:20px;
  }

}


.connect-collaboratep-container{
	background-color:white !important;
	margin-top:30px;
}

.connect-collaborate{
	text-align:left;
	max-width:400px;
	font-size:50px;
	font-weight:bold;
	line-height:25px;
	margin-bottom:20px;
	margin-left:5vw;
}

.connect-collaborate .collab{
	
}

.connect-collaborate .create{
	text-align:left;
}



.connect-collaboratep-container .collab-card{
	background-color:white;
	border-radius:15px;
	width:250px;
	margin:auto;
}

.connect-collaboratep-container .collab-card:hover{ 
  box-shadow: 5px 10px 20px 1px #fd9b02 !important;
} 

.connect-collaboratep-container .collab-card .card-text{ 
  font-size: 1rem;
  padding: 0.1rem 0.9rem ;
  
} 


.connect-collaboratep-container .card-img-top{
	display:block;
	width:85%;
}

.connect-collaboratep-container .partner-container{
	width:100vw;
	text-align:center;
	margin-bottom:40px;
}

.connect-collaboratep-container .partner-btn{
	display:block;
	font-weight:bold;
}

.connect-collaboratep-container .partner-btn a{
	color:white;
	text-decoration:none;
}


 @media screen and (max-width:670px) {
	 .connect-collaborate{
		 font-size:30px;
	 }
	
	.connect-collaboratep-container .partner-btn button{
		max-width:100px;
		margin:0 auto;
	}
 }
 

/* Media.css */
.media {
    display:block;
    background-color: #f0f4f8;
    padding: 60px 20px;
    text-align: center;
    justify-content:center;
    align-items:center;
  }
  
  .media h2 {
    font-size: 3.5rem;
    margin-bottom: 40px;
    color: #333;
    font-weight:600;
  }
  .media h5 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  .media-cards {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    flex-wrap: wrap;
    justify-content:center;
    align-items:center;
  }
  
  .media-card:hover {
    transform: scale(1.05);
  }
  
  .media-card img {
    width: 100%;
    height: auto;
    text-align:center;
    justify-content:center;
    align-items:center;
    /* display: block; */
  }
  @media (max-width: 768px) {
    .media h2{
      font-size:30px;
    }
  }  
.textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  color: white;
  height: 60%;
  justify-content: space-between;
}

.activeContainer {
  display: flex;
  align-items: center;
  margin-bottom: 50%;
}

.activeItem {
  display: flex;
  align-items: center;
}

.activeContainer img {
  padding-left: 10px;
}

.textContainer h1 {
  margin-bottom: 0px;
}

@media (min-width: 320px) and (max-width: 1200px) {
  .textContainer {
    display: none;
  }
}
.messageBox {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #2979FF;
}

.backgroundLight {
  background: #F3F3F3;
}

.messages {
  padding: 5% 0;
  overflow: auto;
  flex: auto;
}
.infoBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2979FF;
  border-radius: 4px 4px 0 0;
  height: 60px;
  width: 100%;
}

.leftInnerContainer {
  flex: 7.6 1;
  display: flex;
  align-items: center;
  margin-left: 5%;
  color: white;
}

.rightInnerContainer {
  display: flex;
  flex: 0.5 1;
  justify-content: flex-end;
  margin-right: 5%;
}

.onlineIcon {
  margin-right: 5%;
}

.form {
  display: flex;
  border-top: 2px solid #D3D3D3;
}

.input {
  border: none;
  border-radius: 0;
  padding: 5%;
  width: 80%;
  font-size: 1.2em;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.sendButton {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #2979FF;
  padding: 20px;
  display: inline-block;
  border: none;
  width: 20%;
}
.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(255, 255, 255);
}

.inner-container {
  padding:0;
  border: 2px solid #2979ff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 8px;
  height: 70%;
  width: 90%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .outerContainer {
    height: 90vh;
  }

  .inner-container {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 480px) and (max-width: 1200px) {
  .inner-container {
    width: 60%;
  }
}

:root {
  --space-4xs: clamp(0.125rem, calc(0.125rem + 0vw), 0.125rem);
  --space-3xs: clamp(0.25rem, calc(0.25rem + 0vw), 0.25rem);
  --space-2xs: clamp(0.5rem, calc(0.5rem + 0vw), 0.5rem);
  --space-xs: clamp(0.75rem, calc(0.75rem + 0vw), 0.75rem);
  --space-sm: clamp(1rem, calc(1rem + 0vw), 1rem);
  --space-md: clamp(1.5rem, calc(1.125rem + 0.78125vw), 1.75rem);
  --space-lg: clamp(2.25rem, calc(1.125rem + 2.34375vw), 3rem);
  --space-xl: clamp(3.5rem, calc(1.25rem + 4.6875vw), 5rem);
  --space-2xl: clamp(5.75rem, calc(2.375rem + 7.03125vw), 8rem);
  --space-3xl: clamp(9.25rem, calc(4.75rem + 9.375vw), 12.25rem);
  --space-4xl: clamp(15rem, calc(7.5rem + 15.625vw), 20rem);
  --text-xs: clamp(0.6875rem, calc(0.59375rem + 0.1953125vw), 0.75rem);
  --text-sm: clamp(0.8125rem, calc(0.625rem + 0.390625vw), 0.9375rem);
  --text-base: clamp(1rem, calc(0.8125rem + 0.390625vw), 1.125rem);
  --text-md: clamp(1.1875rem, calc(0.90625rem + 0.5859375vw), 1.375rem);
  --text-lg: clamp(1.4375rem, calc(1.15625rem + 0.5859375vw), 1.625rem);
  --text-xl: clamp(1.75rem, calc(1.375rem + 0.78125vw), 2rem);
  --text-2xl: clamp(2.0625rem, calc(1.40625rem + 1.3671875vw), 2.5rem);
  --text-3xl: clamp(2.5rem, calc(1.75rem + 1.5625vw), 3rem);
  --text-4xl: clamp(3rem, calc(2.0625rem + 1.953125vw), 3.625rem);
}

body {
  background-color: #fff;
  color: #37383f;
  font: 400 var(--text-base) / 1.2 Inter, sans-serif;
}

.ticker {
  align-items: center;
  display: flex;
  justify-content: center;
  /* left: -1rem; */
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  /* right: -1rem; */
  top: 0;
  z-index: 1;
  width: 100%;
}

@keyframes ticker {
  0% {
    transform: translateZ(0);
    visibility: visible;
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker__list {
  align-items: center;
  backface-visibility: hidden;
  /* background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#e07a03),
    color-stop(10%, #dc7776),
    color-stop(20%, #d26fd1),
    color-stop(30%, #98984d),
    color-stop(40%, #6296dd),
    color-stop(50%, #50a1a1),
    color-stop(60%, #6296dd),
    color-stop(70%, #98984d),
    color-stop(80%, #d26fd1),
    color-stop(90%, #dc7776),
    to(#e07a03)
  ); */
  /* background-image: -o-linear-gradient(
    left,
    #e07a03 0%,
    #dc7776 10%,
    #d26fd1 20%,
    #98984d 30%,
    #6296dd 40%,
    #50a1a1 50%,
    #6296dd 60%,
    #98984d 70%,
    #d26fd1 80%,
    #dc7776 90%,
    #e07a03 100%
  );
  background-image: linear-gradient(
    to right,
    #e07a03 0%,
    #dc7776 10%,
    #d26fd1 20%,
    #98984d 30%,
    #6296dd 40%,
    #50a1a1 50%,
    #6296dd 60%,
    #98984d 70%,
    #d26fd1 80%,
    #dc7776 90%,
    #e07a03 100%
  ); */
  background-color: #5a159e;
  display: flex;
  grid-gap: var(--space-sm);
  gap: var(--space-sm);
  justify-content: center;
  /* left: -25%; */
  padding: var(--space-2xs) calc(var(--space-sm) / 2);
  position: relative;
  transform: translateZ(0);
  transform-style: preserve-3d;
  white-space: nowrap;
  will-change: transform;
  width: 100%;
}

.ticker__item {
  color: #fff;
  font-size: var(--text-md);
}

@media screen and (max-width: 768px) {
  .ticker__item {
    animation: ticker 15s 0s linear infinite;
  }
}

html,
body {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
}
/* 
#root {
  height: 100vh;
} */

* {
  box-sizing: border-box;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: #1a1a1d;
}

.joinInnerContainer {
  width: 20%;
}

.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
}

.heading {
  color: white;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

.button {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #2979ff;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
  }
}

button:focus {
  outline: 0;
}

.connect-collaboratep-container{
	background-color:white !important;
	margin-top:30px;
}

.connect-collaborate{
	text-align:left;
	max-width:400px;
	font-size:50px;
	font-weight:bold;
	line-height:25px;
	margin-bottom:20px;
	margin-left:5vw;
}

.connect-collaborate .collab{
	
}

.connect-collaborate .create{
	text-align:left;
}



.connect-collaboratep-container .collab-card{
	background-color:white;
	border-radius:15px;
	width:250px;
	margin:auto;
}

.connect-collaboratep-container .collab-card:hover{ 
  box-shadow: 5px 10px 20px 1px #fd9b02 !important;
} 

.connect-collaboratep-container .collab-card .card-text{ 
  font-size: 1rem;
  padding: 0.1rem 0.9rem ;
  
} 


.connect-collaboratep-container .card-img-top{
	display:block;
	width:85%;
}

.connect-collaboratep-container .partner-container{
	width:100vw;
	text-align:center;
	margin-bottom:40px;
}

.connect-collaboratep-container .partner-btn a{
	color:white;
	text-decoration:none;
}

.connect-collaboratep-container .partner-container{
		display:flex;
		justify-content:center;
}


 @media screen and (max-width:670px) {
	 .connect-collaborate{
		 font-size:30px;
	 }
	
	.connect-collaboratep-container .partner-btn button{
		max-width:100px;
		margin:0 auto;
	}
 }
 

html {
  scroll-behavior: smooth;
  font-size: 1rem;
}

body {
  overflow-x: hidden;
  background-color: white;
}

.navbar {
  background-color: white !important;
  color: black !important;
  box-shadow: none !important;
}

.nav-item {
  height: auto !important;
}

.login-button {
  background-color: #fd9b02;
  padding: 0 35px;
  border-radius: 25px;
}

.login-button a {
  color: white;
  text-decoration: none;
}

.navbar .login-button a.login:hover,
.navbar a.login:focus {
  color: black !important;
}

.multilevel-ddown-head {
  background: none;
  border: none;
  font-weight: 500;
}

@media screen and (max-width: 400px) {
  .Submenu_submenu__2dVDP {
    left: -20px !important;
  }
}
.multilevel-ddown-head a:hover {
  text-decoration: none;
}

/*------------ nav-item border-bottom animation----------- */
.navbar li.different {
  border: none;
  position: relative;
}
.different::after {
  content: "";
  position: absolute;
  width: 0px;
  height: 2px;
  left: 0%;
  bottom: 0;
  background-color: #ffc107;
  transition: all ease-in-out 0.4s;
}
.different:hover::after {
  width: 100%;
  left: 0;
}
/* ---------------------------- */

/* Mentor */


.nav-tabs-question {
  border-bottom: 0.2rem solid #ffc107;
}
.nav-tabs-question .nav-item.active {
  background: #ffc107;
  color: white !important;
  border: none;
}
.cursor-pointer {
  cursor: pointer;
}

/* flip box css */

.flip-box {
  background-color: transparent;
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

/* rotateX for teamPage */

.flip-box-team:hover .flip-box-inner-team {
  transform: rotateX(180deg);
}

/* end */

.flip-box-front,
.flip-box-back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #bbb;
  color: black;
}

.flip-box-back {
  position: absolute;
  top: 1px;
  background-color: #555;
  color: white;
  transform: rotateY(180deg);
}

/* img{
border: 2px solid white;
} */

.social-links div.col {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.footer-social-media {
  padding: 10px;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 50%;
  margin: 5px 0;
}
a.footer-social-media {
  text-decoration: none;
}

.footer-social-media:hover {
  /* opacity: 0.7; */
}

.fa-facebook {
  color: white;
}
/* .fa-facebook:hover {
/* background: rgb(115, 158, 250); */
/* color: white;
} */
*/ .fa-twitter {
  color: white;
}
.fa-twitter:hover {
  /* background: rgb(133, 202, 255); */
  color: white;
}

.fa-instagram {
  color: white;
}

/* .fa-instagram:hover {
/* background: #3eabfa; */
/* color: white;
} */

.fa-linkedin {
  color: white;
}
/* 
.fa-linkedin:hover {
color: #007bb5;
} */

.fa-youtube {
  color: white;
}
/* 
.fa-youtube:hover {
background: #bb0000;
color: white;
} */

/* Start Admin Dashboard Page */
.dashboard {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.cutTheText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30vw;
}

.showTheText {
  white-space: normal;
  max-width: 30vw;
}

.callbackChart {
  width: 500px;
  margin-bottom: 2rem;
}

.admin-list {
  margin: 0 auto;
}

.grey-bg {
  background-color: #f5f7fa;
}

.baseline-icon {
  width: 500%;
  color: orange;
}

/* End Admin Dashboard Page */

/* Mentee */
.badgePosition {
  float: right;
}
.queryTitle {
  color: #007bff;
  /* #007bb5 */
}
.queryDesp {
  font-size: 20px;
}
.mainTitle {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.subTitle {
  text-align: center;
  color: blue;
}

.hideDetails {
  display: none;
}

/* end here */
/* Test Colours */
.grey {
  /* background-color:grey; */
  border: 2px solid grey;
}

.red {
  border: 2px solid red;
}

.green {
  border: 2px solid green;
}

.violet {
  border: 2px solid violet;
}

.orange {
  border: 2px solid white;
}
/* test end here */


/* Buttons  */


.btn-primary {
  justify-content:center;
	align-items:center;
	background-color:#5a159e;
	border:none;
	padding:15px 30px;
	border-radius:22px;
	color:white;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-primary:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(90, 21, 158, 0.4);
  background-color: #ff9500;
}
