@import "https://cdn.jsdelivr.net/gh/jgthms/minireset.css@master/minireset.min.css";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap";

:root {
  --space-4xs: clamp(0.125rem, calc(0.125rem + 0vw), 0.125rem);
  --space-3xs: clamp(0.25rem, calc(0.25rem + 0vw), 0.25rem);
  --space-2xs: clamp(0.5rem, calc(0.5rem + 0vw), 0.5rem);
  --space-xs: clamp(0.75rem, calc(0.75rem + 0vw), 0.75rem);
  --space-sm: clamp(1rem, calc(1rem + 0vw), 1rem);
  --space-md: clamp(1.5rem, calc(1.125rem + 0.78125vw), 1.75rem);
  --space-lg: clamp(2.25rem, calc(1.125rem + 2.34375vw), 3rem);
  --space-xl: clamp(3.5rem, calc(1.25rem + 4.6875vw), 5rem);
  --space-2xl: clamp(5.75rem, calc(2.375rem + 7.03125vw), 8rem);
  --space-3xl: clamp(9.25rem, calc(4.75rem + 9.375vw), 12.25rem);
  --space-4xl: clamp(15rem, calc(7.5rem + 15.625vw), 20rem);
  --text-xs: clamp(0.6875rem, calc(0.59375rem + 0.1953125vw), 0.75rem);
  --text-sm: clamp(0.8125rem, calc(0.625rem + 0.390625vw), 0.9375rem);
  --text-base: clamp(1rem, calc(0.8125rem + 0.390625vw), 1.125rem);
  --text-md: clamp(1.1875rem, calc(0.90625rem + 0.5859375vw), 1.375rem);
  --text-lg: clamp(1.4375rem, calc(1.15625rem + 0.5859375vw), 1.625rem);
  --text-xl: clamp(1.75rem, calc(1.375rem + 0.78125vw), 2rem);
  --text-2xl: clamp(2.0625rem, calc(1.40625rem + 1.3671875vw), 2.5rem);
  --text-3xl: clamp(2.5rem, calc(1.75rem + 1.5625vw), 3rem);
  --text-4xl: clamp(3rem, calc(2.0625rem + 1.953125vw), 3.625rem);
}

body {
  background-color: #fff;
  color: #37383f;
  font: 400 var(--text-base) / 1.2 Inter, sans-serif;
}

.ticker {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* left: -1rem; */
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  /* right: -1rem; */
  top: 0;
  z-index: 1;
  width: 100%;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker__list {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#e07a03),
    color-stop(10%, #dc7776),
    color-stop(20%, #d26fd1),
    color-stop(30%, #98984d),
    color-stop(40%, #6296dd),
    color-stop(50%, #50a1a1),
    color-stop(60%, #6296dd),
    color-stop(70%, #98984d),
    color-stop(80%, #d26fd1),
    color-stop(90%, #dc7776),
    to(#e07a03)
  ); */
  /* background-image: -o-linear-gradient(
    left,
    #e07a03 0%,
    #dc7776 10%,
    #d26fd1 20%,
    #98984d 30%,
    #6296dd 40%,
    #50a1a1 50%,
    #6296dd 60%,
    #98984d 70%,
    #d26fd1 80%,
    #dc7776 90%,
    #e07a03 100%
  );
  background-image: linear-gradient(
    to right,
    #e07a03 0%,
    #dc7776 10%,
    #d26fd1 20%,
    #98984d 30%,
    #6296dd 40%,
    #50a1a1 50%,
    #6296dd 60%,
    #98984d 70%,
    #d26fd1 80%,
    #dc7776 90%,
    #e07a03 100%
  ); */
  background-color: #5a159e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: var(--space-sm);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* left: -25%; */
  padding: var(--space-2xs) calc(var(--space-sm) / 2);
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  white-space: nowrap;
  will-change: transform;
  width: 100%;
}

.ticker__item {
  color: #fff;
  font-size: var(--text-md);
}

@media screen and (max-width: 768px) {
  .ticker__item {
    -webkit-animation: ticker 15s 0s linear infinite;
    animation: ticker 15s 0s linear infinite;
  }
}

html,
body {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
}
/* 
#root {
  height: 100vh;
} */

* {
  box-sizing: border-box;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: #1a1a1d;
}

.joinInnerContainer {
  width: 20%;
}

.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
}

.heading {
  color: white;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

.button {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #2979ff;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
  }
}

button:focus {
  outline: 0;
}
