.discover-container {
	margin: 100px 32px; 
	padding: 50px; 
	background-color: #5a159e;
	border-radius: 20px;
  }

  .discover-container .title {
	font-size: 3.5rem;
	color: white;
	text-align:center;
	margin-bottom: 50px;
	font-weight:500;
  }
  .title .mento-meet {
	color: #ff9500;
  }
  
    
  .discover-body {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 30px;
	font-size:1.2rem;
  }
  ul.custom-bullets li {
    list-style: none; /* Remove default bullets */
    position: relative; /* Position for ::before element */
    padding-left: 20px; /* Space for the custom bullet */
  }

  ul.custom-bullets li::before {
    content: "•"; /* Custom bullet symbol */
    position: absolute;
    left: 0;
    color: white; /* Custom bullet color */
  }


  .discover-body li {
	font-size: 20px;
	color: white;
	font-weight: 200;
	text-align:left;
	letter-spacing: 1px;
	margin-bottom: 8px;
	width:40vw;
	justify-content: center;
  }

 
  @media screen and (max-width: 768px) {
	.discover-container {
		margin-top: 30px;
		padding: 20px;
	}
	.discover-container .title {
	  font-size: 30px;
	}
  
	.discover-body li {
	  font-size: 1.1rem;
	  line-height: 1.5;
	}

	ul.custom-bullets li {
		width: 100%;
	}
  
	/* YouTube video adjustments */
	iframe {
	  width: 100%;  /* Make YouTube video responsive */
	  height: auto;

	}
  }
