/* Existing styles */
.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 150px;
  margin-top: 60px;
}

.hero-section h2 {
  position: relative;
  font-size: 4.5rem;
  font-weight: bold;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  animation: slideInTop 1s ease-in-out;
  margin-top: 20px;
  color: #1a1a1a;
}

.hero-section p {
  margin-bottom: 10px;
  font-size: 2.4rem;
  color: #1a1a1a;
  animation: fadeIn 2s ease-in-out;
  font-weight: 600;
}

.hero-section .coding {
  position: absolute;
  top: 20%;
  right: -40px;
}

.hero-section .highlight {
  color: #5a159e;
}

.mentor-btn, .faq-btn {
  padding: 1.2rem;
  border: none;
  border-radius: 24px;
  margin-top: 30px;
}

.mentor-btn {
  background: #5a159e;
  color: #fff;
  transform: scale(1.1);
}

.hero-section .main-action-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 300px;
}

.hero-section .main-action-buttons button {
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  animation: fadeInUp 1.5s ease-in-out;
  width: 100%;
}

.hero-section .main-action-buttons button:hover {
  background: #ff9500;
}

.hero-section .supporting-text p {
  font-size: 30px;
  font-weight: 200;
  width: 60%;
  text-align: center;
  margin: 80px auto 50px auto;
}

/* Responsive styles */

/* For screens up to 768px */
@media screen and (max-width: 768px) {
  .hero-section h2 {
    font-size: 30px;
  }

  .hero-section p {
    font-size: 1.8rem;
    padding: 0 10px;
  }

  .hero-section .main-action-buttons {
    width: 80%;
  }

  .hero-section .supporting-text p {
    font-size: 1.4rem;
    width: 80%;
  }

  .hero-section .coding {
    position: relative;
    top: 0;
    right: 0;
    max-width: 300px;
    margin-top: 20px;
  }
}

/* For screens up to 487px */
@media screen and (max-width: 487px) {
  .hero-section h2 {
    font-size: 2rem;
  }

  .hero-section p {
    font-size: 1.2rem;

  }

  .hero-section .mentor-btn {
    padding: 1rem;
    font-size: 0.9rem;
    transform: scale(1);
  }

  .hero-section .main-action-buttons {
    width: 60%;
  }

  .hero-section .supporting-text p {
    font-size: 1rem;
    width: 90%;
  }

  .hero-section .coding {
    display: none; /* Hides the image on very small screens */
  }
}





/* Apply general styles for the section */
.Quality {
  text-align: center;
  margin-top: 30px;
}

.Quality h2 {
  font-size: 4.2rem; /* Slightly smaller for mobile */
  margin-bottom: 3.5rem;
  color: #1a1a1a;
  gap: 20px;
}
/* .Quality .mento-meet{
  background:#ff9500;
} */

/* Options container */
.Quality-options {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.Quality .option-btn {
  display: flex;
  justify-content: center;
}

.Quality .option-btn button {
  justify-content: center;
  align-items: center;
  background-color: #5a159e;
  border: none;
  padding: 25px;
  border-radius: 22px;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px;
  margin-bottom: 70px;
  width: 20%; /* Adjusted for smaller screens */
}

.Quality .option-btn button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(90, 21, 158, 0.4);
  background-color: #ff9500;
}

/* Individual card styles */
.option {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  width: 22%;
  min-width: 250px; /* Ensures a reasonable size for cards */
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.option:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.option h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.option h5 {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}


/* Responsive layout for screens up to 489px */
@media (max-width: 489px) {
  .Quality h2 {
    font-size:30px;
    margin-bottom: 1rem;
  }

  .Quality .option-btn button {
    padding: 20px;
    font-size: 0.9rem;
    width: 80%; /* Adjust width for smaller screens */
  }

  .option {
    width: 100%; /* Full width for cards */
    min-width: auto;
    padding: 1rem;
  }

  .option h3 {
    font-size: 1.2rem;
  }

  .option h5 {
    font-size: 0.9rem;
  }
}



/* stats*/
.New-stats-container {
  width: 100%;
  margin-top:40px;
}

.New-stats-container .stats-counts {
  display: flex;
}

/* Flex container adjustments */
.New-stats-container .small-stats-container {
  display: flex;
  gap: 30px;
  justify-content: space-around;
  margin-bottom: 60px;
  width: 100%;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

/* Stat card styling */
.New-stats-container .stats-container {
  background-color: #5a159e;
  padding: 18px;
  border-radius: 30px;
  color: white;
  width: 100%;
  margin: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for stat cards */
.New-stats-container .stats-container:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(90, 21, 158, 0.4);
  background-color: #ff9500;
}

/* Inner stat card content */
.New-stats-container .stats-body {
  display: flex;
  padding: 10px;
  justify-content: center;
  text-align:center;
}

/* Title styling */
.stats-body .title {
  font-weight: 700;
  font-size: 50px;
  margin-right: 10px;
  color: #fff;
  flex-shrink: 0;
}

/* Sub-title styling */
.stats-body .sub-title {
  font-size: 30px;
  color: white;
}

/* Responsive layout for screens up to 768px */
@media (max-width: 768px) {
  .New-stats-container .small-stats-container {
    gap: 15px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .New-stats-container .stats-container {
    width: 90%;
    margin: 10px 0;
    padding: 15px;
  }

  .stats-body .title {
    font-size: 24px;
  }

  .stats-body .sub-title {
    font-size: 16px;
  }
}

/* Responsive layout for screens up to 486px */
@media (max-width: 486px) {
  .New-stats-container .small-stats-container {
    gap: 10px;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }

  .New-stats-container .stats-container {
    width: 100%;
    margin: 5px 0;
    padding: 10px;
  }

  .stats-body .title {
    font-size: 20px;
  }

  .stats-body .sub-title {
    font-size: 14px;
  }

  .purecounter {
    font-size: 1em;
  }
}

/* Keyframe animation */
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}






/*carousel*/

.carousel-container {
  text-align: center;
  margin: 20px;
  font-family: Arial, sans-serif;
  margin-bottom:20px;
}

.carousel-container .main-title {
  font-size: 3.5rem;
  color: #1a1a1a;
  margin-bottom: 40px;
  margin-top:20px;
}

.video-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between videos */
  flex-wrap: wrap; /* Wrap videos to the next row on smaller screens */
}

.video-wrapper {
  flex: 1 1 300px; /* Videos will adjust their size to fit the container */
  max-width: 1000px;
}

.mentor-video {
  width: 100%;
  height: 380px; /* Adjust height as needed */
  border-radius: 10px; /* Optional rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  border: none;
}







// works
/* Main Container Styling */
.how-we-work-container {
  margin: 40px auto;
  padding: 20px;
  text-align: center;
  font-family: "Arial", sans-serif;
}

.how-we-work-container h2 {
  font-size: 3.5rem;
  font-weight: bold;
  color: #1a1a1a; /* Purple color */
  margin-bottom: 20px;
  padding-bottom: 10px;
}

/* Grid Container */
.how-we-work-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Always 2 columns */
  gap: 20px;
  padding: 20px;
}

/* Card Styling */
.work-card {
  background: linear-gradient(145deg, #f8f9fa, #ffffff); /* Light gradient background */
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px 15px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.work-card:hover {
  transform: translateY(-5px); /* Lift on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Icon Styling */
.work-card .heading {
  font-size: 1.8rem;
  color: #5a159e; /* Purple theme */
  margin-bottom: 0px;
  font-weight:bold;
}

/* Text Styling */
.work-card p {
  font-size: 1.2rem;
  color: #1a1a1a;
  line-height: 1.6;
  font-weight: 200;
}

/* Hover Effect for Icons */
.work-card:hover .icon {
  color: #ff9500; /* Lighter purple on hover */
}

/* Provide Container Styling */
.provide-container {
  margin: 20px auto;
  padding: 20px;
  text-align: center;
}
.mento-meet {
  color: #ff9500;
}


.provide-container h2 {
  font-size: 3.5rem;
  font-weight: bold;
  color: #1a1a1a;
  border-bottom: 2px solid #5a159e;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

/* Item Container for the Grid */
.item-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Default grid for large screens */
  gap: 20px;
  padding: 20px;
}

/* Individual Key Item */
.key-item {
  display: flex;
  flex-direction: column; /* Ensure items stack vertically */
  align-items: center;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden; /* Ensure no overflow */
}

/* Image Styling */
.key-item img {
  width: 100%;
  height: 200px;
  object-fit: contain; /* Fit within the container */
  border-radius: 8px;
  background-color: white;
}

/* Heading Styling */
.key-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin: 10px 0;
}

/* Paragraph Styling */
.key-item p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

/* Hover Effect for Key Items */
.key-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background: #ff9500;
}

/* Responsive Adjustments for Screens up to 768px */
@media screen and (max-width: 768px) {
  .provide-container h2 {
    font-size: 2.5rem;
  }

  .item-container {
    grid-template-columns: 1fr 1fr; /* Adjust grid to 2 columns */
    gap: 15px;
  }

  .key-item {
    padding: 15px;
  }

  .key-item img {
    height: 150px; /* Reduce image size */
  }

  .key-item h3 {
    font-size: 1.3rem;
  }

  .key-item p {
    font-size: 0.95rem;
  }
}

/* Responsive Adjustments for Screens up to 487px */
@media screen and (max-width: 768px) {
  .provide-container h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .item-container {
    grid-template-columns: 1fr; /* Single-column layout */
    gap: 10px;
  }

  .key-item {
    padding: 10px;
  }

  .key-item img {
    height: 120px; /* Further reduce image size */
  }

  .key-item h3 {
    font-size: 1.1rem;
  }

  .key-item p {
    font-size: 0.85rem;
  }

  .hero-mentor-section .main-action-buttons {
    margin: 0px;
  }
  .hero-mentor-section p{
    justify-content:center;
    text-align:center;
  }

  .hero-mentor-section .supporting-text p {
    margin: 20px;
  }

  .Quality {
    margin-top: 10px;
  }

  .Quality-options {
    flex-direction: column;
    align-items: center;
  }

  .Quality-options .option {
    width: 90%;
    margin-left: 10px;
  }

  .Quality h2 {
    font-size: 30px;
  }

  .Quality .option-btn button {
    width: 70%; /* Adjust width for medium screens */
    margin-bottom: 5px;
  }

  .New-stats-container {
    width: 80%;
  }

  .how-we-work-container h2 {
    font-size: 30px;
    width: 80%;
    margin: 10px auto;
  }

  .how-we-work-grid {
    grid-template-columns: 1fr; /* Stacks into 1 column for smaller devices */
  }
  .work-card .icon {
    font-size: 2.5rem;
  }

  .work-card p {
    font-size: 0.9rem;
  }

  .Quality-options .option {
    margin-left: 10px;
  }
  .Quality-options .option h3 {
    font-size:25px;
  }
  .carousel-container .main-title {
    font-size:30px;
  }
 
}

