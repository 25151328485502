.grid-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);; 
	gap: 20px;
	padding: 20px;
 
  }
  
  .key-item {
	display: block;
	justify-content: center;
    align-items:center;
    text-align:center;
	/* background-color: #f9f9f9; */
   padding:20px;
	border-radius: 8px;
	/* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
	min-height: 300px; 
	width: 100%; 
	
  }
  
  .key-item h3 {
    margin-top: 10px;
    padding: 0;
    font-weight:bold;
    font-size:25px;
  }
  .key-item p {
	margin-top: 10px;
	padding: 0;
  font-weight:200;

  }
  
  .key-item .text-content {
	flex: 1;
  }
  .key-item button{
    background-color:#FF9500;
    padding:25px;
    border:none;
    color:white;
    border-radius:32px;
    margin-top:60px;
    width:100%;
    font-size:30px;
  }

  .functions-container {
      margin: 10px 32px;

  }

  .functions-container h2{
    text-align:center;
    font-size:55px;
    font-weight:600;
    animation: fadeIn 1s ease-in-out;
    border-bottom:1px solid #5a159e;
    margin-bottom:20px;
  }
  .key-item p:hover{
    color:white;
  }
  
  @media screen and (max-width: 576px) {
    .grid-container {
      grid-template-columns: 1fr;
      gap: 15px;
    }
    .key-item {
      padding: 15px;
    }
  }
  
  /* Media Query for devices with width <= 769px */
  @media screen and (max-width: 769px) {
    .grid-container {
      grid-template-columns: 1fr; /* 1 column on very small devices */
      gap: 10px;
    }
    .key-item {
      padding: 10px;
    }
    .key-item h3 {
      font-size: 1.2rem; /* Reduce heading size */
    }
    .key-item p {
      font-size: 0.9rem; /* Reduce paragraph size */
    }
    .key-item p:hover{
      color:white;
    }
  
    .functions-container h2 {
      font-size: 30px; /* Reduce the main heading size */
    }
  }