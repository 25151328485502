.connect-collaboratep-container{
	background-color:white !important;
	margin-top:30px;
}

.connect-collaborate{
	text-align:left;
	max-width:400px;
	font-size:50px;
	font-weight:bold;
	line-height:25px;
	margin-bottom:20px;
	margin-left:5vw;
}

.connect-collaborate .collab{
	
}

.connect-collaborate .create{
	text-align:left;
}



.connect-collaboratep-container .collab-card{
	background-color:white;
	border-radius:15px;
	width:250px;
	margin:auto;
}

.connect-collaboratep-container .collab-card:hover{ 
  box-shadow: 5px 10px 20px 1px #fd9b02 !important;
} 

.connect-collaboratep-container .collab-card .card-text{ 
  font-size: 1rem;
  padding: 0.1rem 0.9rem ;
  
} 


.connect-collaboratep-container .card-img-top{
	display:block;
	width:85%;
}

.connect-collaboratep-container .partner-container{
	width:100vw;
	text-align:center;
	margin-bottom:40px;
}

.connect-collaboratep-container .partner-btn a{
	color:white;
	text-decoration:none;
}

.connect-collaboratep-container .partner-container{
		display:flex;
		justify-content:center;
}


 @media screen and (max-width:670px) {
	 .connect-collaborate{
		 font-size:30px;
	 }
	
	.connect-collaboratep-container .partner-btn button{
		max-width:100px;
		margin:0 auto;
	}
 }
 
