/* Footer container */
.footer {
  background-color: #1c1c1c;
  color: #fff;
  padding: 40px 20px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

/* .brand-social .navbar-logo {
	height:50px;
	width:50px;
	margin-left:20px;
  } */
.footer-brand-logo {
  width: 300px;
  height: 70px;
  margin-left: -50px;
  /* margin-right: 200px; */
  /* margin-top:-70px; */
}
/* Brand and Social Media */
.brand-social {
  flex: 1;
  /* min-width: 250px; */
}
.brand-social .title-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -30px;
}

.brand-social .web-title {
  font-size: 2rem;
  font-weight: 600;
}

.social-icons {
  display: flex;
  gap: 10px;
  margin-left: -40px;
  /* margin-top:-50px; */
}

.social-icons a {
  color: #fff;
  font-size: 2.1rem;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #f0ad4e;
}

/* Support Section */
.support {
  flex: 1;
  min-width: 200px;
}

.support h4 {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.support ul {
  list-style-type: none;
  padding: 0;
}

.support ul li {
  margin-bottom: 10px;
}

.support ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.support ul li a:hover {
  color: #f0ad4e;
}

/* Contact Section */
.contact {
  flex: 1;
  min-width: 250px;
}

.contact h4 {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.contact p {
  margin-bottom: 10px;
}

.contact i {
  margin-right: 10px;
}
.contact p:hover {
  color: #f0ad4e;
}
/* Raise Complaint */
.complaint {
  flex: 1;
  min-width: 250px;
}

.complaint h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.complaint form {
  display: flex;
  flex-direction: column;
}

.complaint input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  color: #fff;
}

.complaint button {
  padding: 10px;
  background-color: #f0ad4e;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.complaint button:hover {
  background-color: #d48b34;
}

.telegram-link {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.telegram-link i {
  margin-right: 10px;
}

.telegram-link i:hover {
  color: #f0ad4e;
}
.footer-content {
  display: inline;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 10px;
}

.footer-last {
  border-top: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap:wrap;
  width: 100%;
}
.footer-center-text {
  font-weight:bold;
}


.footer-last ul {
  display:flex;
  margin: 10px;
  justify-content: center;
  display: flex;
  gap: 10px;
  text-align: center;
  font-size: 18px;
  flex-wrap: wrap;

}

.footer-last ul li {
  cursor: pointer;
  padding-right: 10px;
  border-right : 1px solid white;
}

.footer-last ul li:last-child {
  border-right: none;
}
/* Default (Desktop View) */
.desktop-text {
  display: inline;
}
.mobile-text {
  display: none;
}

/* Mobile View (up to 768px) */
@media (max-width: 768px) {
  .desktop-text {
    display: none;
  }
  .mobile-text {
    display: inline;
  }
}


/* Responsive design */
@media (max-width: 768px) {
  .footer {
    padding-left: 60px;
  }
  .footer-container {
    flex-direction: column;
  }

  .footer-container .navbar-logo {
    margin-left: 65px;
  }

  .footer-container > div {
    margin-bottom: 20px;
  }

  .footer-container .social-icons {
    margin-left: 0px;
  }

  .footer-last ul  {
    margin: 0px;
  }
  .footer-brand-logo {
    width:200px;
    height:50px;
    margin-left:20px;
  }

}

