#about{
	flex-direction:column;
    font-size:16px;
	height:auto;
    h1{
        font-size:3.6em;
        font-weight: 900;
        color:orange;
		width:100%;
    }
    .square{
        width:250px;
        height:200px;
        box-shadow: 1px 1px 50px rgb(219, 219, 219);
        border-radius: 15px;

        img{
            width:100px;
        }

        p{
            font-size:1.7em;
            color:gray;
            margin-top:5px;
          
        }
     
    }
}