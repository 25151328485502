.profile-section{
	margin-top:85px
}

.profile-section .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin: 3vh 3vw;
    background:white;
  }
  
 .profile-section .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .profile-section .container1 {
    padding: 2px 16px;
    display: flex;
    padding-top: 15vh;
    padding-left: 10vw;
    flex-wrap:wrap;
    
  }
  
  .profile-section  .container1 img{
	  width:200px;
  }
  
   .profile-section  .container1 .image{
	   width:100%;
   }
  
  .profile-section .stars{
      padding-left: 30px;
      padding-top: 10px;
  }
  .profile-section .fa-star{
      padding-left: 10px;
      color: orange;
  }

  b{
      padding-left: 30px;
      font-size: 20px;
     
  }

  .profile-section .qualify{
      padding-left: 25px;
      padding-top: 10px;
  }

  .profile-section  .achieve{
      list-style-type: none;
  }

  .profile-section  .span{
      padding-left: 10px;
  }

  .profile-section .name-des{
      padding-left: 100px;
      padding-top: 15px;
  }

  .profile-section .name{
      font-size: 40px;
      font-weight: bold;
	  text-align:left;
  }
  
  .profile-section .desig{
    font-size: 20px;
    color: grey;
	text-align:center;
  }

  .profile-section .achieved{
      padding-top:40px;
  }
  
  .profile-section .achieve{
      font-size: 20px;
  }
  
  .profile-section li{
	padding-top: 8px;
  }

  .profile-section .handle{
      padding-left: 8vw;
      padding-top: 10px;
      font-size: 50px;
  }
  
  .profile-section .handle .fa-instagram,.profile-section .handle .fa-linkedin{
	  background-color:white;
	  padding:5px;
	  color:black;
  }
  
 .profile-section  a{
      padding-right: 2vw;
      color: rgb(0, 0, 0);
  }

  .profile-section.percent{
      padding-left: 10vw;
  }

  .profile-section span1{
      color: orange;
      font-size: 20px;
  }
  
  @media screen and (max-width:900px){
	  .profile-section .card {
		margin:10px 10px;
	  }
	  
	  .profile-section .container1 {
			padding: 10px 15px;
			display: flex;
			flex-wrap:wrap;
			justify-content:center;
	  }
	  
	   .profile-section .container1 img{
		   height:50%;
	   }
	   
	     .profile-section .name-des{
		  padding-left: 0px;
		  padding-top: 5px;
	  }
	  
  }