:root{
    --primary: rgb(0, 0, 0);
}

#HOMEPAGE .btn{ 
    display: inline-block;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 50px;
    min-width: 250px;
    min-height: 50px;
	text-decoration:none;
	font-weight:550;
}

#HOMEPAGE .btn--outline{
    position: relative;
    background-color: #5A159E;
    color:white;
    border: 1px solid #5A159E;
    transform: all 0.3s ease-out;
}
#HOMEPAGE .btn--join1{
    position: relative;
    background-color: transparent;
    color-adjust: var(--primary);
    
    border: 5px solid var(--primary);
    border-radius: 100px;
    transform: all 0.3s ease-out;
    
}
#HOMEPAGE .btn--login1{
        background-color: transparent;
        color-adjust: var(--primary);
        
        padding: 8px 20px;
        border: 1px solid var(--primary);
        transform: all 0.3s ease-out;
        
    }
#HOMEPAGE .btn--medium{
    padding: 20px 20px;
    font-size: 23px;
}
#HOMEPAGE .btn--login{
    padding: 8px 20px;
    font-size: 20px;
}
#HOMEPAGE .btn--large{
    
    font-size: 2opx;
}
#HOMEPAGE .btn--join{
    left: -80px;
    border-color: orange;
    font-size: 20px;
    border-width:3px;
    padding-top: 15px;
    padding-bottom: 15px;
    
}

#HOMEPAGE .btn--large:hover,#HOMEPAGE .btn--medium:hover,#HOMEPAGE .btn--login:hover{
    color: #5A159E;
    background: white;
    transition:all 0.3s ease-out;
    border:1px solid #5A159E;
}

#HOMEPAGE .btns-active{
	background:#fa9c09 !important;
    color: white !important;
    border:none;
}
  


@media screen and (max-width: 1500px) {
    

   #HOMEPAGE .btn--login{
        left: -110px;
        font-size: 20px;
        top: 100px;
    }

    #HOMEPAGE .btn--login1{
        background-color: transparent;
        color-adjust: var(--primary);
        
        padding: 8px 20px;
        border: 1px solid var(--primary);
        transform: all 0.3s ease-out;
    
    }
    #HOMEPAGE .btn--join{
        left: 0px
    }



}
