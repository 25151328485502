.mentor-dashboard {
    display: flex; 
    /* height: 100vh; */
    overflow: hidden; 
  }
  
  .sidebar {
    width: 20%; 
    background-color: #f4f4f4; 
    padding: 20px; 
    display: flex;
    flex-direction: column; 
    justify-content: flex-start; 
    height: 100vh; 
    position: fixed; 
    left: 0;
    top: 0;
  }
  
  .sidebar h5 {
    font-size: 1.8rem; 
    font-weight: bold; 
    margin-bottom: 30px; 
  }
  
  .sidebar ul {
    list-style: none; 
    padding: 0; 
  }
  
  .sidebar li {
    margin-bottom: 15px; 
    font-size: 1.2rem; 
    cursor: pointer;
    color: #333; 
  }
  
  .sidebar li:hover {
    color: #6c63ff; 
  }

  
  .header {
    text-align: right; 
    margin-bottom: 20px; 
  }
  
  .header h1 {
    font-size: 2.5rem; 
  }
  
  .header p {
    font-size: 1.2rem;
    color: gray;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: flex-end; 
    margin-bottom: 20px; 
  }
  
  .find-mentor-btn,
  .schedule-meeting-btn {
    margin-right: 10px; 
    padding: 5px 10px;
    font-size: 0.95rem;
    border-radius: 5px; 
  }
  
  .find-mentor-btn {
    background-color: #6c63ff; 
    color: white; 
    border: none;
  }
  
  .schedule-meeting-btn {
    background-color: #ff6f61; 
    color: white; 
    border: none;
  }
  
  .question-list {
    margin-top: 20px; 
    display: flex;
    flex-wrap: wrap; 
    gap: 20px;
  }
  
  .question-card {
    flex: 1 1 calc(33.333% - 20px); 
    margin-bottom: 20px; 
    border: 1px solid #ddd;
    border-radius: 8px; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  }
  
  .details-btn {
    background-color: #6c63ff; 
    color: white; 
    border: none; 
    margin-top: 10px; 
    padding: 10px 15px; 
    font-size: 1rem; 
  }
  