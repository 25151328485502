.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(255, 255, 255);
}

.inner-container {
  padding:0;
  border: 2px solid #2979ff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 8px;
  height: 70%;
  width: 90%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .outerContainer {
    height: 90vh;
  }

  .inner-container {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 480px) and (max-width: 1200px) {
  .inner-container {
    width: 60%;
  }
}
