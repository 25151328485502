.collaboration {
  background-color: #f4f4f4;
  padding: 50px 20px;
  text-align: center;
}

.collaboration h2 {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 30px;
}
.collaboration .option h3 {
  font-size:1.2 rem;
}

.collaboration h2 span {
  color: #5a159e;
}

.collaboration-options {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 40px 0;
}

.option {
  background-color: #5a159e;
  padding: 20px;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width:80%;
  max-width: 100%;
  text-align: left;
}

.option:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.option h3 {
  font-size: 2rem;
  margin-bottom: 15px;
  font-weight:600;
  color:#ffff;
  justify-content:center;
  text-align:center;
}

.option h5 {
  font-size: 1.1em;
  margin-bottom: 20px;
  color: #ffff;
  justify-content:center;
  text-align:center;
}

.option button {
  background-color: #5a159e;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.option button:hover {
  background-color: #FF9500;
  transform: translateY(-5px);
}

.collaboration h2:last-of-type {
  font-size: 2.5em;
  margin-top: 30px;
  margin-bottom: 30px;
}

.collaboration h2 span {
  color: #FF9500;
}

.contact-us {
  background-color: #5a159e;
  color: white;
  padding: 20px 40px;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-us:hover {
  background-color: #FF9500;
  transform: translateY(-5px);
}

/* Animation for options appearing on load */
.option {
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 0.8s ease forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
}
}
/* Responsive Styling */
@media (max-width: 768px) {
  .collaboration h2 {
    font-size: 30px;
  }
  .collaboration-options {
    flex-direction: column;
    align-items: center;
  }

  .option {
    width: 100%;
    margin-bottom: 20px;
  }

  .collaboration h2:last-of-type {
    font-size: 2rem;
  }
}
