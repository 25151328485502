/* Overall Layout */
.mentee-history-container {
  display: flex;
  font-family: "Arial", sans-serif;
  background-color: #f8f9fa;
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: #343a40;
  color: white;
  padding: 20px;
}

.sidebar-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
}

.sidebar-menu li {
  padding: 10px 0;
  font-size: 16px;
  cursor: pointer;
  color: #ced4da;
}

.sidebar-menu li.active {
  color: #5a159e;
  font-weight: bold;
}

.sidebar-menu li:hover {
  color: #ffffff;
}

/* Main Content */
.main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #ffffff;
}

.page-title {
  font-size: 28px;
  color: #343a40;
  margin-bottom: 20px;
}

.history-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* History Card */
.history-card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
  transition: transform 0.3s ease;
}

.history-card:hover {
  transform: scale(1.02);
}

.card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.status-badge {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 15px;
  text-transform: capitalize;
  height: fit-content;
}

.status-badge.approved {
  background-color: #ff9500;
  color: white;
}

.status-badge.pending {
  background-color: #ffc107;
  color: white;
}

.category {
  font-size: 14px;
  background-color: #5a159e;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
}

.card-body p {
  font-size: 14px;
  color: #343a40;
  margin-bottom: 10px;
}

.meet-link {
  display: inline-block;
  font-size: 14px;
  color: white;
  background-color: #5a159e;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}

.meet-link:hover {
  background-color: #ff9500;
}

.pending-message {
  color: #6c757d;
}

/* No History */
.no-history {
  font-size: 16px;
  color: #6c757d;
  text-align: center;
  margin-top: 20px;
}
