.hero-new-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white !important;
  background-repeat: no-repeat;
  background-position: right, right;
  background-size: 40%, 60%;
  background-position-x: 55vw, 50vw;
  background-position-y: 40vh, -50vh;
  padding: 20px;
  animation: fadeIn 1.5s ease-in-out;
  width: 100%;
}

.hero-new-container .main-title {
  position: relative;
  font-size: 4.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  animation: slideInTop 1s ease-in-out;
  margin-top: 20px;
  color: #1a1a1a;
}

.hero-new-container .main-title .looking {
  color: #5A159E;
}


.hero-new-container .abstract-line {
  margin-bottom: 10px;
  position: absolute;
  top: -15px;
  left: -37px;
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 30px;
  animation: fadeIn 2s ease-in-out;
  margin-top: -10px;
}
.btn1, .btn2 {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 24px;
}

.hero-new-container .hero-btns button{
  padding: 15px;
  width: 200px;
}

.hero-new-container .hero-btns .btn1 {
  background: #5a159e;
  color: #fff;
  transform: scale(1.1);

}

.hero-new-container .hero-btns .btn2 {
  background: #ff9500;
  color: #fff;
  transform: scale(1.1);
}
.hero-new-container .hero-btns {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0px;
  margin-top: 30px;
  radius: 2rem;
  padding: 30px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  animation: fadeInUp 1.5s ease-in-out;
  width:100%;
}

.hero-new-container h3 {
  color: #1a1a1a;
  font-size: 2.8rem;
  padding-top: 0px;
  margin-top: 10px;
  text-align: center;
  font-weight: 550;
}

.hero-new-container p {
  color: #1a1a1a;
  font-size: 25px;
  width: 70%;
  text-align: center;
}


.hero-new-container .hero-home-right {
  margin-top: 20px;
  animation: fadeInRight 2s ease-in-out;
  svg {
    position: absolute;
    right: 0;
    top: 340px;
    max-width: 100%;
    height: auto;
  }
}

/* Default styles */
/* Fade-in animation for the container */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation applied to the container */
.hero-new-container .small-stats-container {
  display: flex;
  flex-wrap: wrap;
 colun-gap: 20px;
 row-gap: 5px;
  justify-content: space-around;
  margin-top: 260px;
  margin-bottom: 10px;
  width: 90%;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

/* Stat card styling */
.hero-new-container .stats-container {
  background-color: #5a159e;
  padding: 18px;
  border-radius: 30px;
  color: white;
  /* width: 100%; */
  margin: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for stat cards */
.hero-new-container .stats-container:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(90, 21, 158, 0.4);
  background-color:#ff9500;
}

/* Inner stat card content */
.hero-new-container .stats-body {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
}

/* Title styling */
.stats-body .title {
  font-weight: 700;
  font-size: 30px;
  margin-right: 10px;
  color: #fff;
  flex-shrink: 0;
}

/* Sub-title styling */
.stats-body .sub-title {
  font-size: 30px;
  color: white;
}


@media (max-width: 768px) {
  .hero-new-container .small-stats-container {
    gap: 15px;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .hero-new-container .stats-container {
    width: 90%;
    margin: 10px 0;
  }

  .stats-body .title {
    font-size: 24px;
  }

  .stats-body .sub-title {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .hero-new-container .small-stats-container {
    gap: 10px;
    margin-top: 200px;
  }

  .hero-new-container .stats-container {
    width: 100%;
    margin: 5px 0;
  }

  .stats-body .title {
    font-size: 22px;
  }

  .stats-body .sub-title {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .hero-new-container .small-stats-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .hero-new-container .stats-container {
    width: 95%;
    padding: 10px;
    margin: 5px 0;
  }

  .stats-body .title {
    font-size: 10px;
  }

  .stats-body .sub-title {
    font-size: 12px;
  }

  .purecounter {
    font-size: 1em;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

/* Responsive CSS starts here */
@media screen and (max-width: 1000px) {
  .hero-new-container h2 {
    font-size: 3.5rem;
  }

  .hero-new-container h3 {
    font-size: 2.2rem;
  }

  .hero-new-container p {
    font-size: 20px;
    width: 80%;
  }


}

@media screen and (max-width: 768px) {
  .hero-new-container h2 {
    font-size: 8rem;
  }

  .hero-new-container h3 {
    font-size: 1.8rem;
  }

  .hero-new-container p {
    font-size: 18px;
    width: 85%;
  }

  .hero-new-container .hero-home-right svg {
    width: 350px;
    height: 350px;
  }
}

@media screen and (max-width: 576px) {
  .hero-new-container h2 {
    font-size: 8rem;
  }

  .hero-new-container h3 {
    font-size: 1.6rem;
  }


  .hero-new-container .hero-home-right svg {
    width: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 768px) {
  .hero-new-container h2 {
    font-size: 6rem;
  }

  .hero-new-container h3 {
    font-size: 1.4rem;
  }

  .hero-new-container .hero-home-right {
    display: none;
  }

  .stats-body .title {
    font-size: 2.5rem;
  }

  .stats-body .sub-title {
    font-size: 1.8rem;
  }
}


@media screen and (max-width: 769px) {
  .hero-new-container .hero-btns  {
    margin-bottom: 10px;
  }
  .hero-new-container .main-title svg {
    display: none;
  }
  .hero-new-container .main-title span {
    font-size: 30px;
  }
  .hero-new-container .sub-main-title {
    font-size: 20px;
  }
}


