/* Media.css */
.media {
    display:block;
    background-color: #f0f4f8;
    padding: 60px 20px;
    text-align: center;
    justify-content:center;
    align-items:center;
  }
  
  .media h2 {
    font-size: 3.5rem;
    margin-bottom: 40px;
    color: #333;
    font-weight:600;
  }
  .media h5 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  .media-cards {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content:center;
    align-items:center;
  }
  
  .media-card:hover {
    transform: scale(1.05);
  }
  
  .media-card img {
    width: 100%;
    height: auto;
    text-align:center;
    justify-content:center;
    align-items:center;
    /* display: block; */
  }
  @media (max-width: 768px) {
    .media h2{
      font-size:30px;
    }
  }  