@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap");

html {
  scroll-behavior: smooth;
  font-size: 1rem;
}

body {
  overflow-x: hidden;
  background-color: white;
}

.navbar {
  background-color: white !important;
  color: black !important;
  box-shadow: none !important;
}

.nav-item {
  height: auto !important;
}

.login-button {
  background-color: #fd9b02;
  padding: 0 35px;
  border-radius: 25px;
}

.login-button a {
  color: white;
  text-decoration: none;
}

.navbar .login-button a.login:hover,
.navbar a.login:focus {
  color: black !important;
}

.multilevel-ddown-head {
  background: none;
  border: none;
  font-weight: 500;
}

@media screen and (max-width: 400px) {
  .Submenu_submenu__2dVDP {
    left: -20px !important;
  }
}
.multilevel-ddown-head a:hover {
  text-decoration: none;
}

/*------------ nav-item border-bottom animation----------- */
.navbar li.different {
  border: none;
  position: relative;
}
.different::after {
  content: "";
  position: absolute;
  width: 0px;
  height: 2px;
  left: 0%;
  bottom: 0;
  background-color: #ffc107;
  transition: all ease-in-out 0.4s;
}
.different:hover::after {
  width: 100%;
  left: 0;
}
/* ---------------------------- */

/* Mentor */


.nav-tabs-question {
  border-bottom: 0.2rem solid #ffc107;
}
.nav-tabs-question .nav-item.active {
  background: #ffc107;
  color: white !important;
  border: none;
}
.cursor-pointer {
  cursor: pointer;
}

/* flip box css */

.flip-box {
  background-color: transparent;
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

/* rotateX for teamPage */

.flip-box-team:hover .flip-box-inner-team {
  transform: rotateX(180deg);
}

/* end */

.flip-box-front,
.flip-box-back {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #bbb;
  color: black;
}

.flip-box-back {
  position: absolute;
  top: 1px;
  background-color: #555;
  color: white;
  transform: rotateY(180deg);
}

/* img{
border: 2px solid white;
} */

.social-links div.col {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.footer-social-media {
  padding: 10px;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 50%;
  margin: 5px 0;
}
a.footer-social-media {
  text-decoration: none;
}

.footer-social-media:hover {
  /* opacity: 0.7; */
}

.fa-facebook {
  color: white;
}
/* .fa-facebook:hover {
/* background: rgb(115, 158, 250); */
/* color: white;
} */
*/ .fa-twitter {
  color: white;
}
.fa-twitter:hover {
  /* background: rgb(133, 202, 255); */
  color: white;
}

.fa-instagram {
  color: white;
}

/* .fa-instagram:hover {
/* background: #3eabfa; */
/* color: white;
} */

.fa-linkedin {
  color: white;
}
/* 
.fa-linkedin:hover {
color: #007bb5;
} */

.fa-youtube {
  color: white;
}
/* 
.fa-youtube:hover {
background: #bb0000;
color: white;
} */

/* Start Admin Dashboard Page */
.dashboard {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.cutTheText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30vw;
}

.showTheText {
  white-space: normal;
  max-width: 30vw;
}

.callbackChart {
  width: 500px;
  margin-bottom: 2rem;
}

.admin-list {
  margin: 0 auto;
}

.grey-bg {
  background-color: #f5f7fa;
}

.baseline-icon {
  width: 500%;
  color: orange;
}

/* End Admin Dashboard Page */

/* Mentee */
.badgePosition {
  float: right;
}
.queryTitle {
  color: #007bff;
  /* #007bb5 */
}
.queryDesp {
  font-size: 20px;
}
.mainTitle {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.subTitle {
  text-align: center;
  color: blue;
}

.hideDetails {
  display: none;
}

/* end here */
/* Test Colours */
.grey {
  /* background-color:grey; */
  border: 2px solid grey;
}

.red {
  border: 2px solid red;
}

.green {
  border: 2px solid green;
}

.violet {
  border: 2px solid violet;
}

.orange {
  border: 2px solid white;
}
/* test end here */


/* Buttons  */


.btn-primary {
  justify-content:center;
	align-items:center;
	background-color:#5a159e;
	border:none;
	padding:15px 30px;
	border-radius:22px;
	color:white;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-primary:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(90, 21, 158, 0.4);
  background-color: #ff9500;
}