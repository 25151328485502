
body{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      margin-top: 0px;
}
#head{
    text-align: center;
    color:black;
    font-style:bold;
	width:100%;
	font-family:Montserrat;
}



h1{
   margin-top:0px;
   font-size: 3.6rem;
   font-weight:900;
   font-family:Montserrat;
   text-shadow: 7px 7px #998d8d82;
}

.slider-area{
background: #F5F5F5;
   padding: 0px 0;
   position: relative;
   margin: 20px 0px;
}

.carousel-item{
		background-color:white;
}

.carousel-control.left,
.carousel-control.right{
   background: #43D5B5;

}

.img-area{
    width: 180px;
    height: 180px;
    margin: auto ;
    /*padding: 2px;*/
    border: 5px solid #fff;
    border-radius: 100%;
    overflow:hidden;
}

.img-area img{
width: 100%;
}

.carousel-caption{
   position: static;
   padding-bottom: 15px;
   padding-top: 0;
   font-weight:300;

}
.carousel-indicators li{
   background-color:black;
}
.carousel-caption h3{
   font-size: 26px;
   margin-bottom: 15px;
   margin-top: 25px;
   color: #FFB13E;
   font-weight:300;
}
.carousel-caption h4{
   color: #43D5B5;
	font-weight:300;
}
.carousel-caption p{
   font-size: 18px;
   margin: auto;
   width: 70%;
   margin-bottom: 10px;
   color: #212529;
	font-weight:300;
}

.carousel-caption p span i{
	color:#ffc107;
	font-size:30px;
	padding-left:10px;
	padding-right:10px;
}

.carousel-indicators{
   bottom: -35px;
   color: #808080;
}

 @media screen and (max-width: 850px) {
	 #head{
		 font-size:20px;
	 }
	 
	 .carousel-caption p{
		 width:100vw;
	
	 }
 }
 
 
  @media screen and (max-width: 500px) {
		 #head{
			 font-size:30px;
		 }
		 
		 .carousel-caption p{
			 width:90vw;
		 }
  }